import React from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import {
    PlayerHead,
    PlayerData,
    PlayerControl,
    PlayerAction,
} from 'components/Applications/player';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { ContentColorsItem } from 'components/AppPage/types';
import { createContentColorsItems } from 'utils/Colors';

const playerColor = [
    colorUsage.color_primary,
    colorUsage.color_accent,
    colorUsage.background_1,
    colorUsage.background_3,
    colorUsage.background_4,
    colorUsage.text_1,
    colorUsage.text_2,
    colorUsage.icon_1,
];

const Player: React.FC = () => {
    const phoneWidth = '236px';
    const { isDark, isGradient, setDark, getColor, hasDarkLightVarian } =
        useConfigUsage();

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            playerColor,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            withoutMiniPlayer
                            transparentStatusBar
                            useBlackTextSystemUiOnLightTheme={true}
                            allowWhiteSystemUi={false}
                        >
                            <div
                                className="py-2 px-3 transition-colors duration-1000"
                                style={{
                                    background: getColor(
                                        colorUsage.background_1
                                    ),
                                    backgroundImage: isGradient
                                        ? `linear-gradient(to bottom, ${getColor(
                                              colorUsage.background_3
                                          )}, ${getColor(
                                              colorUsage.background_4
                                          )})`
                                        : '',
                                }}
                            >
                                <PlayerHead />
                                <PlayerData />
                                <PlayerControl />
                                <PlayerAction />
                            </div>
                        </PhoneFrame>

                        <Switch
                            active={isDark}
                            onChange={setDark}
                            activeText="Dark"
                            offText="Light"
                            removeColorActive={false}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Player;
