import React, { FC } from 'react';
import { BaseProps } from 'types';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface SwitchProps extends BaseProps {
    active: boolean;
    removeColorActive?: boolean;
    activeText?: string;
    offText?: string;
    onChange?: (active: boolean) => void;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const variant = {
    xs: {
        width: '18px',
        height: '8px',
        thumb: '10px',
        top: '-1px',
        transtale: '8px',
        text: '8px',
    },
    sm: {
        width: '26px',
        height: '12px',
        thumb: '14px',
        top: '-1px',
        transtale: '12px',
        text: '10px',
    },
    md: {
        width: '36px',
        height: '16px',
        thumb: '20px',
        top: '-2px',
        transtale: '16px',
        text: '12px',
    },
    lg: {
        width: '56px',
        height: '24px',
        thumb: '28px',
        top: '-2px',
        transtale: '28px',
        text: '16px',
    },
    xl: {
        width: '64px',
        height: '32px',
        thumb: '36px',
        top: '-2px',
        transtale: '28px',
        text: '18px',
    },
};
const Switch: FC<SwitchProps> = ({
    active,
    activeText = 'on',
    offText = 'off',
    removeColorActive = false,
    onChange = () => {},
    size = 'md',
}) => {
    const { getColor } = useConfigUsage();
    const styleTrack = {
        width: variant[size].width,
        height: variant[size].height,
        backgroundColor:
            removeColorActive || !active
                ? ''
                : getColor(colorUsage.color_primary),
    };

    const styleThumb = {
        width: variant[size].thumb,
        height: variant[size].thumb,
        top: variant[size].top,
        backgroundColor: getColor(colorUsage.color_primary),
        transform: `translateX(${active ? variant[size].transtale : '0px'})`,
    };

    return (
        <div className="flex align-middle items-center justify-center gap-2 select-none">
            <div style={{ fontSize: variant[size].text }}>{offText}</div>
            <div id="switch" onClick={() => onChange(!active)}>
                <div
                    className={`relative rounded-full cursor-pointer transition-colors ${
                        removeColorActive || !active ? 'bg-slate-300' : ''
                    }`}
                    style={styleTrack}
                >
                    <div className="bg-black/50 absolute top-0 bottom-0 left-0 right-0  rounded-full"></div>
                    <div
                        className={`rounded-full absolute  transition-all shadow-lg`}
                        style={styleThumb}
                    ></div>
                </div>
            </div>
            <div style={{ fontSize: variant[size].text }}>{activeText}</div>
        </div>
    );
};

export default Switch;
