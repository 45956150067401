import React, { FC, useState, useCallback, useEffect } from 'react';
import { BaseProps } from 'types';
import { PopoverColorPicker } from 'components';
import { ColorResult } from 'react-color';
import { addAlpha, withHash, safetyColorHex } from 'utils/Colors';
import { underscoreToSpace } from 'utils/Text';
import bgColor from 'assets/bg_color.svg';

interface ColorListProps extends BaseProps {
    name?: string;
    value?: string;
    onChange?: (clr: string) => void;
    showColorPicker?: boolean;
}
const ColorListitem: FC<ColorListProps> = ({
    name,
    value = '#FFFFFF',
    className,
    showColorPicker = false,
    onChange = () => {},
}) => {
    const [show, setShow] = useState(false);
    const [color, setColor] = useState(value);

    const onColorClick = () => setShow(!show);
    const closeColorPicker = () => setShow(false);
    const onColorChange = useCallback((clr: ColorResult) => {
        setColor(addAlpha(safetyColorHex(clr.hex), clr.rgb.a));
    }, []);
    const onColorChangeComplate = useCallback(
        (clr: ColorResult) => {
            onChange(addAlpha(safetyColorHex(clr.hex), clr.rgb.a));
        },
        [onChange]
    );

    useEffect(() => {
        return () => {
            setShow(false);
        };
    }, []);

    return (
        <div
            className={`flex items-center align-top  justify-start gap-x-2 ${className}`}
        >
            <PopoverColorPicker
                show={show}
                onClose={closeColorPicker}
                color={color}
                onColorChange={onColorChange}
                onColorChangeComplate={onColorChangeComplate}
            >
                <div
                    className="w-8 h-8 rounded-lg cursor-pointer 
                    hover:scale-110 border-[1px] border-white overflow-hidden 
                    bg-repeat"
                    style={{ backgroundImage: `url(${bgColor})` }}
                >
                    <div
                        className=" w-8 h-8 bg-svaraPrimary  cursor-pointer transition-all "
                        style={{ backgroundColor: withHash(color) }}
                        onClick={onColorClick}
                    ></div>
                </div>
            </PopoverColorPicker>
            <div className="w-44 text-left select-none">
                {underscoreToSpace(name)}
            </div>
        </div>
    );
};

export default ColorListitem;
