import React, { FC } from 'react';
import { Input } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';

const SectionGoogle: FC = () => {
    const {
        google: { webClient },
    } = useSvaraConfig();
    const { changeGoogle } = useSvaraConfigAction();

    const onWebClientChange = (value: string) => {
        changeGoogle('webClient', value);
    };

    return (
        <div className="flex flex-wrap gap-x-16 gap-y-5">
            <Input
                className="md:w-full lg:w-4/5 xl:w-8/12"
                name="Web Client"
                width="100%"
                value={webClient}
                onChange={onWebClientChange}
            />
        </div>
    );
};

export default SectionGoogle;
