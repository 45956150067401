import React, { FC } from 'react';
import { IconProps } from './type';

const IconLiveVisual: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 32 32" fill="none" width={size} height={size}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.39174 4C1.07082 4 0 5.07082 0 6.39174V22.9132C0 24.2341 1.07082 25.3049 2.39174 25.3049H29.3533C30.6742 25.3049 31.745 24.2341 31.745 22.9132V6.39174C31.745 5.07082 30.6742 4 29.3533 4H2.39174ZM29.2439 6.44617H2.63579V21.9925H29.2439V6.44617Z"
                fill="currentColor"
            />
            <path
                d="M12.2031 18.99V10.0795C12.2031 9.39198 12.9449 8.9601 13.5428 9.2995L21.5622 13.8516C22.1742 14.199 22.1661 15.0836 21.5478 15.4196L13.5283 19.7781C12.9307 20.1029 12.2031 19.6702 12.2031 18.99Z"
                fill="currentColor"
            />
            <rect
                x="8.16016"
                y="26.5098"
                width="14.8645"
                height="1.48645"
                fill="currentColor"
            />
            <rect
                x="12.6172"
                y="25.8008"
                width="5.9458"
                height="0.707834"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconLiveVisual;
