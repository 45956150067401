import React from 'react';
import {
    AppConfigActionContext,
    AppConfigContext,
    AppConfigUsage,
} from 'provider/context/SvaraConfigContext';

const useSvaraConfig = () => React.useContext(AppConfigContext);

const useSvaraConfigAction = () => React.useContext(AppConfigActionContext);

const useConfigUsage = () => React.useContext(AppConfigUsage);

export { useSvaraConfig, useSvaraConfigAction, useConfigUsage };
