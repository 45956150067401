import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { LinkText } from 'components';

interface ToRegisterProps {
    text?: string;
    link?: string;
}

const ToRegister: FC<ToRegisterProps> = ({ text, link }) => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className="text-[10px]"
            style={{ color: getColor(colorUsage.text_1) }}
        >
            {text} <LinkText text={link} />
        </div>
    );
};

export default ToRegister;
