import React, { FC } from 'react';
import { IconProps } from './type';

const IconRepeat: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 22 22" fill="none" width={size} height={size}>
            <path
                d="M5.38496 15.5789H12.9933L15.1812 18.3789H5.38496C3.93731 18.3789 2.76002 17.1231 2.76002 15.5789V9.97891H0.791322C0.536703 9.97891 0.305709 9.82211 0.196774 9.57571C0.0891514 9.32931 0.123276 9.03951 0.287334 8.83091L3.5685 4.63091C3.69319 4.46991 3.87693 4.37891 4.07249 4.37891C4.26805 4.37891 4.45179 4.46991 4.57648 4.63091L7.85765 8.83091C8.02171 9.03951 8.05583 9.32931 7.94821 9.57571C7.83927 9.82211 7.60828 9.97891 7.35366 9.97891H5.38496V15.5789Z"
                fill="currentColor"
            />
            <path
                d="M20.4783 12.7789C20.733 12.7789 20.9639 12.9357 21.0729 13.1821C21.1805 13.4285 21.1464 13.7183 20.9823 13.9269L17.7012 18.1269C17.5765 18.2879 17.3914 18.3789 17.1972 18.3789C17.0029 18.3789 16.8179 18.2879 16.6932 18.1269L13.412 13.9269C13.2479 13.7183 13.2138 13.4285 13.3214 13.1821C13.4304 12.9357 13.6614 12.7789 13.916 12.7789H15.8847V7.17891H8.27632L6.08844 4.37891H15.8847C17.3323 4.37891 18.5096 5.63471 18.5096 7.17891V12.7789H20.4783Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconRepeat;
