import React, { FC, useState } from 'react';
import {
    UserRemoveIcon,
    ChevronLeftIcon,
    ChatAlt2Icon,
} from '@heroicons/react/solid';
import image7 from 'assets/images/image-7.jpg';
import image1 from 'assets/images/image-1.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const ProfileHeader: FC = () => {
    const [isFollow, setIsFolow] = useState(true);
    const { getColor } = useConfigUsage();

    const onFollowClick = () => {
        setIsFolow(!isFollow);
    };
    return (
        <div
            className=" bg-cover bg-center"
            style={{ backgroundImage: `url(${image7})` }}
        >
            <div
                className="flex flex-col items-center pt-8 relative"
                style={{
                    backgroundImage: `linear-gradient(to bottom, ${getColor(
                        colorUsage.gradient_start_color
                    )} , ${getColor(
                        colorUsage.gradient_middle_color
                    )}, ${getColor(colorUsage.gradient_end_color)})`,
                }}
            >
                <div
                    className="absolute left-2"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    <ChevronLeftIcon className="w-6" />
                </div>
                <div
                    className="bg-gray-500 bg-cover bg-center rounded-full aspect-[1/1] w-16 "
                    style={{ backgroundImage: `url(${image1})` }}
                />

                <div
                    className="text-xs"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    First Last Name
                </div>
                <div
                    className="text-[9px]"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    @username
                </div>
                <div
                    className="flex border-[1px] rounded-lg transition-colors duration-1000"
                    style={{
                        borderColor: getColor(colorUsage.color_primary),
                        backgroundColor: getColor(colorUsage.background_1),
                    }}
                >
                    <div
                        className="flex items-center justify-between gap-1 text-[10px] px-3 py-[2px] 
                        rounded-r-none rounded-l-lg cursor-pointer transition-colors"
                        style={{
                            backgroundColor: isFollow
                                ? getColor(colorUsage.color_primary)
                                : '',
                            color: isFollow ? 'white' : '',
                        }}
                        onClick={onFollowClick}
                    >
                        <UserRemoveIcon className="h-3" />
                        <div>Followed</div>
                    </div>
                    <div
                        className="w-px h-[10px]]"
                        style={{
                            backgroundColor: getColor(colorUsage.color_primary),
                        }}
                    ></div>
                    <div
                        className="flex items-center justify-between gap-1 text-[10px]  px-3 py-[2px] 
                        rounded-r-lg rounded-l-none"
                    >
                        <ChatAlt2Icon className="h-3" />

                        <div>Message</div>
                    </div>
                </div>

                <div
                    className="w-full flex text-[8px] px-4 py-1 justify-around"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    <div>700 Followers</div>
                    <div>•</div>
                    <div>1811 Followings</div>
                </div>
            </div>
            <div
                className="text-[8px] px-2 pt-1 text-justify transition-colors duration-1000"
                style={{
                    backgroundColor: getColor(colorUsage.background_1),
                    color: getColor(colorUsage.text_1),
                }}
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
        </div>
    );
};

export default ProfileHeader;
