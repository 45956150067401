import React, { FC } from 'react';
import { SocialItem } from 'components/Applications/social';

const ProfileContent: FC = () => {
    return (
        <div className="w-full">
            <SocialItem />
            <SocialItem />
        </div>
    );
};

export default ProfileContent;
