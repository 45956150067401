const MenuList = [
    {
        name: 'Application',
        link: '/application',
    },
    {
        name: 'Assets',
        link: '/assets',
    },
    {
        name: 'Colors',
        link: '/colors',
    },
    {
        name: 'Login',
        link: '/login',
    },
    {
        name: 'Home',
        link: '/home',
    },
    {
        name: 'Playlist',
        link: '/playlist',
    },
    {
        name: 'Channel',
        link: '/channel',
    },
    {
        name: 'Social',
        link: '/social',
    },
    {
        name: 'Profile',
        link: '/profile',
    },
    {
        name: 'Player',
        link: '/player',
    },
    {
        name: 'Chat',
        link: '/chat',
    },
    {
        name: 'Library',
        link: '/library',
    },
    {
        name: 'Setting',
        link: '/setting',
    },
    {
        name: 'Search',
        link: '/search',
    },
];

export { MenuList };
