const floatAlphaToHex = (opacity: number): string => {
    const hex = Math.round(opacity * 255)
        .toString(16)
        .toUpperCase();
    if (hex.length === 1) return '0' + hex;
    return hex;
};

const normalizeHex = (color: string): string => {
    color = color.replace('#', '');

    if (color.length === 3 || color.length === 4)
        return color
            .split('')
            .map((c: string) => c.repeat(2))
            .join('');

    if (color.length === 6 || color.length === 8) return color;

    if (color.length <= 6) color += '000000';

    return color.substr(0, 6);
};

const addAlpha = (color: string, alpha = 1): string => {
    if (alpha === 1) return normalizeHex(color);
    const opacity = floatAlphaToHex(alpha);
    const normalColor = normalizeHex(color);

    return normalizeHex(normalColor + opacity);
};

const toAndroidHex = (color: string): string => {
    const normalColor = normalizeHex(color);
    if (normalColor.length <= 6) return withHash(color);

    return withHash(
        normalColor.substr(6) + normalColor.substr(0, 6)
    ).toUpperCase();
};

const fromAndroidHex = (color: string): string => {
    const normalColor = normalizeHex(color);
    if (normalColor.length <= 6) return withHash(color);

    return withHash(normalColor.substr(2) + normalColor.substr(0, 2));
};

const withHash = (color: string): string => {
    return '#' + normalizeHex(color);
};

const createContentColorsItems = (
    colors: string[],
    isDark: boolean,
    getColor: (val: string) => string,
    hasDarkLightVarian: (val: string) => boolean
) => {
    return colors.map((val) => {
        const key: string = (isDark ? 'dark_' : 'light_') + val;
        const hasDarkLight = hasDarkLightVarian(val);
        return {
            key: hasDarkLight ? key : val,
            value: getColor(val),
        };
    });
};

const safetyColorHex = (colorHex: string) => {
    if (colorHex.toLocaleLowerCase() === 'transparent') return '#000000';
    return colorHex;
};

export {
    floatAlphaToHex,
    addAlpha,
    toAndroidHex,
    withHash,
    fromAndroidHex,
    createContentColorsItems,
    safetyColorHex,
};
