import React, { FC } from 'react';
import { AppPagelayout } from 'layout';
import { ColorListitem } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';
import { fromAndroidHex } from 'utils/Colors';

const Colors: FC = () => {
    const { color } = useSvaraConfig();
    const { changeColor } = useSvaraConfigAction();

    const onColorChanged = (key: string, clr: string) => {
        changeColor(key, clr);
    };

    return (
        <AppPagelayout>
            <div className="w-full flex overflow-x-hidden relative p-4 gap-4 flex-wrap justify-start align-top">
                {Object.entries(color).map(([key, value]) => {
                    return (
                        <ColorListitem
                            key={key}
                            name={key}
                            value={fromAndroidHex(value)}
                            onChange={(clr) => onColorChanged(key, clr)}
                        />
                    );
                })}
            </div>
        </AppPagelayout>
    );
};

export default Colors;
