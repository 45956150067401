import React, { FC } from 'react';
import { IconProps } from './type';

const IconPinMessage: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" width={size} height={size}>
            <path
                d="M21 3C20.9109 3 20.8195 3.01641 20.7281 3.05391L7.21875 8.48203H3.375C3.16875 8.48203 3 8.65547 3 8.87109V15.8789C3 16.0945 3.16875 16.268 3.375 16.268H5.75625C5.66953 16.5398 5.625 16.8281 5.625 17.1211C5.625 18.6656 6.88594 19.9219 8.4375 19.9219C9.73594 19.9219 10.8305 19.0406 11.1539 17.85L20.7305 21.6984C20.8219 21.7336 20.9133 21.7523 21.0023 21.7523C21.3984 21.7523 21.7523 21.4195 21.7523 20.9742V3.77812C21.75 3.33281 21.3984 3 21 3ZM8.4375 18.2414C7.81641 18.2414 7.3125 17.7398 7.3125 17.1211C7.3125 16.8586 7.40391 16.6078 7.57031 16.4086L9.56016 17.2078C9.51328 17.7844 9.02812 18.2414 8.4375 18.2414Z"
                fill={color}
            />
        </svg>
    );
};

export default IconPinMessage;
