import React, { FC } from 'react';
import { BaseProps } from 'types';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface LoginButtonProps extends BaseProps {
    text?: string;
    icon?: React.ReactNode;
    variant?: 'contained' | 'outlined';
}

const LoginButton: FC<LoginButtonProps> = ({
    className,
    text,
    icon,
    variant = 'contained',
}) => {
    const { getColor } = useConfigUsage();

    const contained = variant === 'contained';

    return (
        <div
            className={`flex items-center justify-center px-2 py-1 rounded-[8px] border-[1px] ${className}`}
            style={{
                backgroundColor: contained
                    ? getColor(colorUsage.color_primary)
                    : 'transparent',
                borderColor: getColor(colorUsage.color_primary),
            }}
        >
            {icon}
            <div
                className="text-[10px] leading-[10px]"
                style={{
                    color: contained ? 'white' : getColor(colorUsage.text_1),
                }}
            >
                {text}
            </div>
        </div>
    );
};

export default LoginButton;
