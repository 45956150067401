import React, { FC } from 'react';
import { IconProps } from './type';

const IconNext: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" width={size} height={size}>
            <path
                d="M18.9848 0.186951C17.8733 0.186951 16.9724 1.08795 16.9724 2.19938V21.1737C16.9724 22.2852 17.8733 23.1862 18.9848 23.1862C20.0962 23.1862 20.9972 22.2852 20.9972 21.1737V2.19938C20.9972 1.08795 20.0962 0.186951 18.9848 0.186951Z"
                fill="currentColor"
            />
            <path
                d="M3.28024 20.2868L11.7014 11.6825L3.28958 3.08807C2.80953 2.5978 2.80953 1.79937 3.28958 1.30891L4.0273 0.555356C4.25918 0.318259 4.56829 0.187742 4.89808 0.187742C5.22805 0.187742 5.53716 0.318259 5.76886 0.555356L15.7863 10.7896C16.0191 11.0273 16.1463 11.3442 16.1453 11.683C16.1463 12.0204 16.0189 12.3377 15.7863 12.5752L5.75952 22.8193C5.52783 23.0564 5.21872 23.187 4.88893 23.187C4.55914 23.187 4.24967 23.0564 4.01815 22.8193L3.28024 22.0658C3.04837 21.8291 2.92081 21.5132 2.92081 21.1761C2.92081 20.8392 3.04837 20.5232 3.28024 20.2868Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconNext;
