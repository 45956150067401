import React, { FC, useState, useEffect } from 'react';
import { Select, CheckBox, Input } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';
import { SvaraConfigSettings } from 'provider/types';
import { toStartCase } from 'utils/Text';

interface ThemeOption {
    label: string;
    value: string;
}

interface ChannelPageStyle {
    label: string;
    value: number;
}

interface DefaultLanguage {
    label: string;
    value?: string;
}

interface NavigationOption {
    label: string;
    value: boolean;
}

const themeOptions: ThemeOption[] = [
    { label: 'Light', value: 'LIGHT_THEME' },
    { label: 'Dark', value: 'DARK_THEME' },
];

const channelPageStyle: ChannelPageStyle[] = [
    { label: 'Default', value: 3 },
    { label: 'Actions With Background', value: 4 },
];

const defaultLanguages: DefaultLanguage[] = [
    { label: 'as System', value: undefined },
    { label: 'English', value: 'en' },
    { label: 'Indonesia', value: 'in' },
];

const navigationOption: NavigationOption[] = [
    { label: 'Top Navigation', value: false },
    { label: 'Bottom Navigation', value: true },
];

const _settings = [
    'blackTextSystemUiOnLightTheme',
    'showAgreementOnRegister',
    'canSignUp',
    'betaVersionLogo',
    'showSkipButton',
    'withFollowFeature',
    'loginPageUseLogo',
    'showVideoProgress',
    'useAnimateSplashScreen',
    'enableToolbarLogo',
    'autoSkipLogin',
    'useChatEngine',
    'autoPlayStreaming',
    'videoIntro',
];

const SectionSettings: FC = () => {
    const { settings } = useSvaraConfig();
    const { changeSettings } = useSvaraConfigAction();

    const [selectedTheme, setSelectedTheme] = useState<ThemeOption>({
        label: '',
        value: '',
    });

    const [selectedChannelPageStyle, setSelectedChannelPageStyle] =
        useState<ChannelPageStyle>({
            label: '',
            value: 3,
        });

    const [selectedDefaultLanguage, setSelectedDefaultLanguage] =
        useState<DefaultLanguage>({
            label: 'Default',
            value: undefined,
        });

    const [selectedNavigation, setSelectedNavigation] =
        useState<NavigationOption>(navigationOption[0]);

    const [urlVideo, setUrlVideo] = useState<string>('');

    useEffect(() => {
        const theme = themeOptions.find(
            (theme) => theme.value === settings.defaultTheme
        );

        if (theme) setSelectedTheme(theme);

        const channelStyle = channelPageStyle.find(
            (channelPage) => channelPage.value === settings.channelPageStyle
        );

        if (channelStyle) setSelectedChannelPageStyle(channelStyle);

        const defLanguage = defaultLanguages.find(
            (lang) => lang.value === settings.defaultLanguage
        );

        if (defLanguage) setSelectedDefaultLanguage(defLanguage);

        const navigation = navigationOption.find(
            (nav) => nav.value === settings.useBottomNavigation
        );

        if (navigation) setSelectedNavigation(navigation);

        if (settings.urlVideo) setUrlVideo(settings.urlVideo);
        if (!settings.videoIntro) {
            setUrlVideoIntro(null);
        }
    }, [settings]);

    const setTheme = (data: ThemeOption) => {
        changeSettings('defaultTheme', data.value);
    };

    const setChannelStyle = (data: ChannelPageStyle) => {
        changeSettings('channelPageStyle', data.value);
    };

    const setDefaultLanguage = (data: DefaultLanguage) => {
        changeSettings('defaultLanguage', data.value);
    };

    const setNavigation = (data: NavigationOption) => {
        changeSettings('useBottomNavigation', data.value);
    };

    const setIsCheck = (field: string, isCheck: boolean) => {
        changeSettings(field, isCheck);
    };

    const setUrlVideoIntro = (url: string | null) => {
        changeSettings('urlVideo', url);
    };

    return (
        <div>
            <div className="flex gap-6 flex-wrap">
                <div>
                    <Select<ThemeOption>
                        width="124px"
                        options={themeOptions}
                        name="Default Theme"
                        value={selectedTheme}
                        onChange={setTheme}
                        getValueString={(val) => val.label}
                    />
                </div>
                <div>
                    <Select<ChannelPageStyle>
                        width="300px"
                        name="Channel Page Style"
                        options={channelPageStyle}
                        value={selectedChannelPageStyle}
                        onChange={setChannelStyle}
                        getValueString={(val) => val.label}
                    />
                </div>
                <div>
                    <Select<NavigationOption>
                        width="200px"
                        name="Menu Navigation"
                        options={navigationOption}
                        value={selectedNavigation}
                        onChange={setNavigation}
                        getValueString={(val) => val.label}
                    />
                </div>
                <div>
                    <Select<DefaultLanguage>
                        width="200px"
                        name="Default Language"
                        options={defaultLanguages}
                        value={selectedDefaultLanguage}
                        onChange={setDefaultLanguage}
                        getValueString={(val) => val.label}
                    />
                </div>
                {Boolean(settings.videoIntro) && (
                    <div>
                        <Input
                            name="Video Intro URL"
                            type="text"
                            value={urlVideo}
                            onChange={setUrlVideoIntro}
                        />
                    </div>
                )}
            </div>
            <div className="mt-5 flex flex-wrap gap-x-16 gap-y-5">
                {_settings.map((field, index) => (
                    <CheckBox
                        key={index}
                        text={toStartCase(field)}
                        checked={Boolean(
                            settings[field as keyof SvaraConfigSettings]
                        )}
                        onChange={(val) => setIsCheck(field, val)}
                        checkPosition="left"
                    />
                ))}
            </div>
        </div>
    );
};

export default SectionSettings;
