import React, { FC } from 'react';
import {
    ChatAlt2Icon,
    HeartIcon,
    DotsHorizontalIcon,
} from '@heroicons/react/solid';
import { IconPlaylist } from 'assets/icons';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PlayerAction: FC = () => {
    const { getColor } = useConfigUsage();

    return (
        <div
            className="flex justify-around pt-[18px] items-end transition-colors duration-1000"
            style={{ color: getColor(colorUsage.icon_1) }}
        >
            <ChatAlt2Icon className="w-5" />
            <HeartIcon className="w-5" />
            <IconPlaylist />
            <DotsHorizontalIcon className="w-5" />
        </div>
    );
};

export default PlayerAction;
