import React, { FC } from 'react';
import {
    SectionTitle,
    ItemTitle,
    ItemSubtitle,
} from 'components/Applications/sections';
import image1 from 'assets/images/image-1.jpg';
import image2 from 'assets/images/image-2.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const SectionWhatsNew: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div className="p-2 w-full overflow-x-hidden">
            <SectionTitle title="What's New" />
            <div className="flex gap-2 w-full">
                <div>
                    <div
                        className="bg-gray-500 bg-cover bg-center rounded-md aspect-[9/14] w-28"
                        style={{ backgroundImage: `url(${image1})` }}
                    ></div>
                    <div style={{ color: getColor(colorUsage.text_1) }}>
                        <ItemTitle />
                        <ItemSubtitle />
                    </div>
                </div>
                <div>
                    <div
                        className="bg-gray-500 bg-cover bg-center rounded-md aspect-[9/14] w-28"
                        style={{ backgroundImage: `url(${image2})` }}
                    ></div>
                    <div style={{ color: getColor(colorUsage.text_1) }}>
                        <ItemTitle />
                        <ItemSubtitle />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionWhatsNew;
