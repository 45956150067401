const AppMenu = [
    { name: 'Application', path: '/application' },
    { name: 'API', path: '/application/api' },
    { name: 'Version', path: '/application/version' },
    { name: 'Setting', path: '/application/settings' },
    { name: 'Credentials', path: '/application/credentials' },
    { name: 'Analytics', path: '/application/analytics' },
    { name: 'AdMob', path: '/application/admob' },
    { name: 'Google', path: '/application/google' },
    { name: 'Facebook', path: '/application/facebook' },
    { name: 'divider', path: '' },
    { name: 'Import From Data', path: '/application/import' },
    { name: 'Export To Data', path: '/application/export' },
];

export default AppMenu;
