import React, { FC } from 'react';
import { Title, Input } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';

const SectionAnalytics: FC = () => {
    const {
        analytics: {
            countly: { apiKey: countlyApiKey, apiCountly },
            playStats: { apiKey: playApiKey },
        },
    } = useSvaraConfig();

    const { changeAnalytics } = useSvaraConfigAction();

    const onApiPlayStatsChange = (value: string) =>
        changeAnalytics('playStats.apiKey', value);

    const onApiKeyCountlyChange = (value: string) =>
        changeAnalytics('countly.apiKey', value);

    const onApiCountlyChange = (value: string) =>
        changeAnalytics('countly.apiCountly', value);

    return (
        <div>
            <Title text="Play Stats" />
            <div className="mt-4 flex flex-col gap-x-16 gap-y-5">
                <Input
                    className="md:w-full lg:w-4/5 xl:w-8/12"
                    name="Api Key"
                    placeholder="google api key"
                    width="100%"
                    value={playApiKey}
                    onChange={onApiPlayStatsChange}
                />
            </div>

            <Title text="Countly" className="mt-10" />
            <div className="mt-4 flex flex-col gap-x-16 gap-y-5">
                <Input
                    className="md:w-full lg:w-4/5 xl:w-8/12"
                    name="Api Key"
                    placeholder="contly api key"
                    width="100%"
                    value={countlyApiKey}
                    onChange={onApiKeyCountlyChange}
                />
                <Input
                    className="md:w-full lg:w-4/5 xl:w-8/12"
                    name="Api Countly"
                    placeholder="https://countly.svara.id"
                    width="100%"
                    value={apiCountly}
                    onChange={onApiCountlyChange}
                />
            </div>
        </div>
    );
};

export default SectionAnalytics;
