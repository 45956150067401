import React, { FC } from 'react';
import { BaseProps } from 'types';
import { CheckBox, ColorListitem } from 'components';
import {
    useConfigUsage,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';
import { ContentColorsItem } from './types';

interface ContentProps extends BaseProps {
    contents: ContentColorsItem[];
}
const ContentColors: FC<ContentProps> = ({ children, className, contents }) => {
    const { changeColor } = useSvaraConfigAction();
    const { isGradient, setGradient } = useConfigUsage();

    const onColorChanged = (key: string, clr: string) => {
        changeColor(key, clr);
    };

    return (
        <div
            className={`flex flex-col gap-6 justify-center items-top place-content-center min-h-[calc(100vh-200px)] ${className} `}
        >
            <div>
                <CheckBox
                    text="Use Gradient"
                    checkPosition="left"
                    checked={isGradient}
                    onChange={setGradient}
                />
                <div className="text-xs mt-2">
                    Background gradient uses background 3 for start color and
                    background 4 for end color
                </div>
            </div>
            <div className="bg-white h-px shadow-md w-full my-3" />
            <div className="flex flex-row flex-wrap gap-4">
                {contents.map(({ key, value }) => (
                    <ColorListitem
                        key={key}
                        name={key}
                        value={value}
                        onChange={(clr) => onColorChanged(key, clr)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ContentColors;
