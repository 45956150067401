import React, { FC, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ChatItem, PinMessage } from 'components/Applications/chat';

const ChatList: FC = () => {
    const scrollbars = useRef<Scrollbars>(null);

    useEffect(() => {
        if (scrollbars && scrollbars.current) {
            scrollbars.current.scrollToBottom();
        }
    }, []);

    return (
        <div className="h-[calc(100%-216px)] relative">
            <Scrollbars autoHide autoHideTimeout={3000} ref={scrollbars}>
                <div className="flex flex-col gap-2 p-2">
                    <ChatItem />
                    <ChatItem />
                    <ChatItem />
                    <ChatItem />
                    <ChatItem />
                </div>
            </Scrollbars>
            <PinMessage />
        </div>
    );
};

export default ChatList;
