import React, { FC } from 'react';
import { BaseProps } from 'types';
import { useConfigUsage, useSvaraConfig } from 'provider/hooks/SvaraConfigHook';
import useAssets from 'provider/hooks/useAssets';
import { colorUsage } from 'provider/context/defaultData';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import image6 from 'assets/images/image-6.jpg';
import toolbarLogoOnDark from 'assets/images/toolbar_logo_on_dark.png';
import toolbarLogoOnLight from 'assets/images/toolbar_logo_on_light.png';

interface Props extends BaseProps {
    title: string;
    bottomOfStatusBar?: boolean;
    iconBack?: boolean;
    withProfile?: boolean;
    enableToolbarLogo?: boolean;
}
const Toolbar: FC<Props> = ({
    title,
    bottomOfStatusBar = true,
    className,
    iconBack,
    withProfile = false,
}) => {
    const { getColor, isDark } = useConfigUsage();
    const {
        settings: { enableToolbarLogo },
    } = useSvaraConfig();
    const { toolbar_logo_on_dark, toolbar_logo_on_light } = useAssets();
    return (
        <div
            className={`z-20 transition-colors duration-1000 ${className}`}
            style={{ backgroundColor: getColor(colorUsage.background_toolbar) }}
        >
            {bottomOfStatusBar && <div className="h-6"></div>}
            <div
                className={`relative flex justify-between items-center w-full py-2 px-2`}
            >
                {(!enableToolbarLogo || iconBack) && (
                    <ChevronLeftIcon
                        className="w-6 transition-colors duration-1000"
                        style={{
                            color: getColor(colorUsage.icon_toolbar),
                            visibility: iconBack ? 'visible' : 'hidden',
                        }}
                    />
                )}

                {enableToolbarLogo && !iconBack && (
                    <img
                        src={
                            isDark
                                ? toolbar_logo_on_dark ?? toolbarLogoOnDark
                                : toolbar_logo_on_light ?? toolbarLogoOnLight
                        }
                        alt="logo"
                        className="h-[16px]"
                    />
                )}

                <div
                    className="font-bold tracking-widest absolute inline left-0 right-0 top-0 
                    translate-y-1/2  align-middle text-center text-sm transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.text_toolbar) }}
                >
                    {title}
                </div>

                <div
                    className="bg-gray-500 bg-cover bg-center rounded-full aspect-[1/1] w-5 h-5"
                    style={{
                        backgroundImage: `url(${image6})`,
                        visibility: withProfile ? 'visible' : 'hidden',
                    }}
                />
            </div>
        </div>
    );
};

export default Toolbar;
