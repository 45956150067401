import React, { FC, useState } from 'react';
import { useConfigUsage, useSvaraConfig } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

import { SectionSquare, SectionCircle } from 'components/Applications/sections';
const ChannelContent: FC = () => {
    const { isGradient, getColor } = useConfigUsage();

    const {
        settings: { channelPageStyle },
    } = useSvaraConfig();

    const [isStayTune, setIsStayTune] = useState(false);
    return (
        <div
            className="shadow-xl rounded-t-xl relative mt-[-8px] pt-6 transition-all duration-1000"
            style={{
                backgroundColor: getColor(colorUsage.background_1),
                backgroundImage: isGradient
                    ? `linear-gradient(to bottom, ${getColor(
                          colorUsage.background_3
                      )}, ${getColor(colorUsage.background_4)})`
                    : '',
            }}
        >
            <div
                className={`absolute top-0 left-[50%] translate-x-[-50%] 
                ${
                    channelPageStyle === 4
                        ? 'top-1.5'
                        : 'top-0 translate-y-[-50%]'
                } px-5 py-1 rounded-md 
                text-center text-xs 
                transition-colors duration-300 cursor-pointer 
                ${isStayTune ? 'border-2' : ''}`}
                style={{
                    backgroundColor: isStayTune
                        ? '#FFFFFF'
                        : getColor(colorUsage.color_primary),
                    borderColor: getColor(colorUsage.color_primary),
                    color: isStayTune
                        ? getColor(colorUsage.color_primary)
                        : '#FFFFFF',
                }}
                onClick={() => setIsStayTune(!isStayTune)}
            >
                STAY TUNE
            </div>
            <SectionSquare />
            <SectionCircle />
        </div>
    );
};

export default ChannelContent;
