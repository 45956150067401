import React, { FC } from 'react';
import { ListItem, PlayButton } from 'components/Applications/playlist';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

import image1 from 'assets/images/image-1.jpg';
import image2 from 'assets/images/image-2.jpg';
import image3 from 'assets/images/image-3.jpg';
import image4 from 'assets/images/image-4.jpg';
import { IconMic, IconAlbum } from 'assets/icons';

const PlaylistContent: FC = () => {
    const { isGradient, getColor } = useConfigUsage();
    return (
        <div
            className="shadow-xl rounded-t-xl relative mt-[-8px] pt-6 transition-all duration-1000"
            style={{
                backgroundColor: getColor(colorUsage.background_1),
                backgroundImage: isGradient
                    ? `linear-gradient(to bottom, ${getColor(
                          colorUsage.background_3
                      )}, ${getColor(colorUsage.background_4)})`
                    : '',
            }}
        >
            <PlayButton />
            {[image1, image2, image3, image4].map((img, index) => (
                <ListItem
                    key={index}
                    img={img}
                    title="Title"
                    line2={{
                        icon: (
                            <IconMic
                                size="8px"
                                color={getColor(colorUsage.icon_1)}
                            />
                        ),
                        text: 'Artist',
                    }}
                    line3={{
                        icon: (
                            <IconAlbum
                                size="8px"
                                color={getColor(colorUsage.icon_1)}
                            />
                        ),
                        text: 'Album',
                    }}
                />
            ))}
        </div>
    );
};

export default PlaylistContent;
