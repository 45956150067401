import React, { FC } from 'react';
import { Input } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';

const SectionAPI: FC = () => {
    const {
        api: {
            baseUrl,
            baseUrlAds,
            baseUrlSocket,
            baseUrlReferral,
            baseUrlAdditionalFeatures,
            baseUrlAnalytics,
            baseUrlPaymentApi,
            baseUrlPaymentWeb,
            baseDomainArticle,
        },
    } = useSvaraConfig();
    const { changeApi } = useSvaraConfigAction();

    const onBaseUrlChange = (value: string) => {
        changeApi('baseUrl', value);
    };

    const onBaseUrlAdsChange = (value: string) => {
        changeApi('baseUrlAds', value);
    };

    const onBaseUrlSocketChange = (value: string) => {
        changeApi('baseUrlSocket', value);
    };

    const onBaseUrlReferralChange = (value: string) => {
        changeApi('baseUrlReferral', value);
    };

    const onBaseUrlAdditionalFeaturesChange = (value: string) => {
        changeApi('baseUrlAdditionalFeatures', value);
    };

    const onBaseUrlAnalyticsChange = (value: string) => {
        changeApi('baseUrlAnalytics', value);
    };

    const onBaseUrlPaymentApiChange = (value: string) => {
        changeApi('baseUrlPaymentApi', value);
    };

    const onBaseUrlPaymentWebChange = (value: string) => {
        changeApi('baseUrlPaymentWeb', value);
    };

    const onBaseDomainArticleChange = (value: string) => {
        changeApi('baseDomainArticle', value);
    };

    return (
        <div className="flex flex-wrap gap-x-16 gap-y-5">
            <Input
                name="Base URL"
                placeholder="https://api.svara.id/v1/"
                width="300px"
                value={baseUrl}
                onChange={onBaseUrlChange}
            />
            <Input
                name="Base URL Ads"
                placeholder="https://api.svara.id/click/v1/"
                width="300px"
                value={baseUrlAds}
                onChange={onBaseUrlAdsChange}
            />
            <Input
                name="Base URL Socket"
                placeholder="https://aws-socket.svara.id"
                width="300px"
                value={baseUrlSocket}
                onChange={onBaseUrlSocketChange}
            />
            <Input
                name="Base URL Referral"
                placeholder="https://api.svara.id/referral/v1/"
                width="300px"
                value={baseUrlReferral}
                onChange={onBaseUrlReferralChange}
            />
            <Input
                name="Base URL Additional Features"
                placeholder="https://api.svara.id/additional-features/v1/"
                width="300px"
                value={baseUrlAdditionalFeatures}
                onChange={onBaseUrlAdditionalFeaturesChange}
            />
            <Input
                name="Base URL Analytics"
                placeholder="https://api.svara.id/analytics/v1/"
                width="300px"
                value={baseUrlAnalytics}
                onChange={onBaseUrlAnalyticsChange}
            />
            <Input
                name="Base URL Payment API"
                placeholder="https://api.svara.id/payment/v1/"
                width="300px"
                value={baseUrlPaymentApi}
                onChange={onBaseUrlPaymentApiChange}
            />
            <Input
                name="Base URL Payment Web"
                placeholder="https://payment.svara.fm/"
                width="300px"
                value={baseUrlPaymentWeb}
                onChange={onBaseUrlPaymentWebChange}
            />
            <Input
                name="Base Domain Article"
                placeholder="articles.svara.id"
                width="300px"
                value={baseDomainArticle}
                onChange={onBaseDomainArticleChange}
            />
        </div>
    );
};

export default SectionAPI;
