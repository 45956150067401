import React from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import {
    ProfileHeader,
    ProfileContentTab,
    ProfileContent,
} from 'components/Applications/profile';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { createContentColorsItems } from 'utils/Colors';
import { ContentColorsItem } from 'components/AppPage/types';

const profileColor = [
    colorUsage.color_primary,
    colorUsage.color_accent,
    colorUsage.background_1,
    colorUsage.background_3,
    colorUsage.background_4,
    colorUsage.text_1,
    colorUsage.text_2,
    colorUsage.text_3,
    colorUsage.icon_1,
    colorUsage.like_color,
    colorUsage.gradient_start_color,
    colorUsage.gradient_middle_color,
    colorUsage.gradient_end_color,
    colorUsage.background_mini_player,
    colorUsage.text_mini_player,
];

const Profile: React.FC = () => {
    const phoneWidth = '236px';
    const { isDark, setDark, getColor, hasDarkLightVarian } = useConfigUsage();

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            profileColor,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            overlapStatusBar
                            transparentStatusBar
                            allowWhiteSystemUi={false}
                        >
                            <ProfileHeader />
                            <ProfileContentTab />
                            <ProfileContent />
                        </PhoneFrame>

                        <Switch
                            active={isDark}
                            onChange={setDark}
                            activeText="Dark"
                            offText="Light"
                            removeColorActive={false}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Profile;
