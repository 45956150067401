import React, { FC, SyntheticEvent, useState } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';

const SectionImport: FC = () => {
    const { fromJson } = useConfigUsage();
    const [appName, setAppName] = useState<string | null>(null);
    const onFileInput = (event: SyntheticEvent<HTMLInputElement>) => {
        setAppName(null);
        if (!event.currentTarget.files || event.currentTarget.files.length < 1)
            return;
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const data = e.target?.result as string;
            const jsonData = JSON.parse(data);
            setAppName(jsonData['displayName'] || null);
            fromJson(jsonData);
        };

        fileReader.readAsText(event?.currentTarget.files[0]);
    };
    return (
        <div className="w-full flex items-center justify-center">
            <div
                className="relative sm:w-full md:w-10/12 lg:w-1/2 xl:w-2/5 aspect-[3/2]
             border-dashed border-white border-4 rounded-xl flex items-center justify-center"
            >
                <input
                    type="file"
                    accept="application/JSON"
                    className="opacity-0 w-full h-full  cursor-pointer absolute"
                    onChange={onFileInput}
                />
                <div className="text-xl font-bold">
                    {appName ? appName : 'Choose File or Drag Here..'}
                </div>
            </div>
        </div>
    );
};

export default SectionImport;
