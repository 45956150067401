import React, { FC } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface Line {
    icon?: React.ReactNode;
    text?: string;
}

interface ListItemProps {
    img?: string;
    title?: string;
    line2?: Line;
    line3?: Line;
    smList?: boolean;
}

const ListItem: FC<ListItemProps> = ({ img, title, line2, line3, smList }) => {
    const { getColor } = useConfigUsage();
    return (
        <div className="flex p-2 pt-0 gap-2">
            <div
                className={`bg-gray-500 bg-cover bg-center rounded aspect-[1/1] w-${
                    smList ? '10' : '12'
                } h-${smList ? '10' : '12'}`}
                style={{ backgroundImage: `url(${img})` }}
            />
            <div className="flex-grow self-center">
                <div
                    className="text-[10px] font-bold transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    {title}
                </div>
                <div className="flex gap-1 items-start">
                    {line2?.icon}
                    <div
                        className="text-[8px] transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_2) }}
                    >
                        {line2?.text}
                    </div>
                </div>
                <div className="flex gap-1 items-start">
                    {line3?.icon}
                    <div
                        className="text-[8px] transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_2) }}
                    >
                        {line3?.text}
                    </div>
                </div>
            </div>
            <DotsVerticalIcon
                className="w-4 transition-colors duration-1000"
                style={{ color: getColor(colorUsage.icon_1) }}
            />
        </div>
    );
};

export default ListItem;
