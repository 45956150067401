import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
    Application,
    Home,
    Assets,
    Chat,
    Colors,
    Login,
    Player,
    Playlist,
    Channel,
    Profile,
    Social,
    Library,
    Setting,
    Search,
} from 'pages';

import {
    SectionFacebook,
    SectionApplication,
    SectionGoogle,
    SectionAdmob,
    SectionAnalytics,
    SectionCredentials,
    SectionSettings,
    SectionVersion,
    SectionExport,
    SectionImport,
    SectionAPI,
} from 'pages/Application';

const Router: React.FC = () => {
    return (
        <Routes>
            <Route index element={<Navigate to="/application" />} />
            <Route path="application" element={<Application />}>
                <Route index element={<SectionApplication />} />
                <Route path="api" element={<SectionAPI />} />
                <Route path="version" element={<SectionVersion />} />
                <Route path="settings" element={<SectionSettings />} />
                <Route path="credentials" element={<SectionCredentials />} />
                <Route path="analytics" element={<SectionAnalytics />} />
                <Route path="admob" element={<SectionAdmob />} />
                <Route path="google" element={<SectionGoogle />} />
                <Route path="facebook" element={<SectionFacebook />} />
                <Route path="import" element={<SectionImport />} />
                <Route path="export" element={<SectionExport />} />
            </Route>
            <Route path="assets" element={<Assets />} />
            <Route path="colors" element={<Colors />} />
            <Route path="login" element={<Login />} />
            <Route path="home" element={<Home />} />
            <Route path="playlist" element={<Playlist />} />
            <Route path="channel" element={<Channel />} />
            <Route path="social" element={<Social />} />
            <Route path="profile" element={<Profile />} />
            <Route path="player" element={<Player />} />
            <Route path="chat" element={<Chat />} />
            <Route path="library" element={<Library />} />
            <Route path="setting" element={<Setting />} />
            <Route path="search" element={<Search />} />
        </Routes>
    );
};

export default Router;
