import React, { FC } from 'react';
import { BaseProps } from 'types';
import PhoneCamera from 'assets/phone_camera.png';
import { useConfigUsage, useSvaraConfig } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import AndroidBackIcon from 'assets/icons/AndroidBackIcon';
import AndroidHomeIcon from 'assets/icons/AndroidHomeIcon';
import AndroidRecentlyAppIcon from 'assets/icons/AndroidRecentlyAppIcon';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
    DotsVerticalIcon,
    WifiIcon,
    ChartBarIcon,
    ClipboardIcon,
    BellIcon,
    ChatAlt2Icon,
    CloudIcon,
} from '@heroicons/react/solid';
import { IconPlay } from 'assets/icons';

interface PhoneProps extends BaseProps {
    width?: string;
    toolbar?: React.ReactNode;
    toolbarHeight?: string;
    tabbar?: React.ReactNode;
    tabbarHeight?: string;
    withoutMiniPlayer?: boolean;
    overlapStatusBar?: boolean;
    transparentStatusBar?: boolean;
    useBlackTextSystemUiOnLightTheme?: boolean;
    allowWhiteSystemUi?: boolean;
}
const PhoneFrame: FC<PhoneProps> = ({
    children,
    className,
    width = '253px',
    toolbar,
    toolbarHeight = '64px',
    tabbar,
    tabbarHeight = '32px',
    withoutMiniPlayer = false,
    overlapStatusBar = false,
    transparentStatusBar = false,
    useBlackTextSystemUiOnLightTheme = true,
    allowWhiteSystemUi = true,
}) => {
    const { getColor, isDark, isGradient } = useConfigUsage();
    const {
        settings: { blackTextSystemUiOnLightTheme },
    } = useSvaraConfig();

    return (
        <div
            className={`border-8 border-black rounded-3xl aspect-w-8 aspect-h-16 bg-white overflow-hidden shadow-lg ${className}`}
            style={{
                width: width,
            }}
        >
            <div
                className={`flex flex-col transition-colors duration-1000`}
                style={{
                    backgroundColor: getColor(colorUsage.background_1),
                    backgroundImage: isGradient
                        ? `linear-gradient(to bottom, ${getColor(
                              colorUsage.background_3
                          )}, ${getColor(colorUsage.background_4)})`
                        : '',
                }}
            >
                <div className="w-full h-full">
                    {!!toolbar && <div className="w-full">{toolbar}</div>}

                    <div
                        className="w-ful select-none"
                        style={{
                            color: getColor(colorUsage.text_1),
                            height: `calc(100% - ${
                                !!toolbar ? toolbarHeight : '0px'
                            })`,
                        }}
                    >
                        <Scrollbars autoHide autoHideTimeout={3000}>
                            {!toolbar && !overlapStatusBar && (
                                <div className="h-[23px]"></div>
                            )}
                            {children}
                        </Scrollbars>
                    </div>
                </div>
                <div
                    className="absolute top-0 w-full transition-colors duration-1000"
                    style={{
                        backgroundColor: transparentStatusBar
                            ? '#00000000'
                            : getColor(colorUsage.status_bar_background),
                    }}
                >
                    <div className="flex justify-items-center items-center justify-center relative p-1">
                        <div
                            className="grow flex gap-1 select-none  transition-colors duration-1000"
                            style={{
                                color: allowWhiteSystemUi
                                    ? useBlackTextSystemUiOnLightTheme &&
                                      blackTextSystemUiOnLightTheme &&
                                      !isDark
                                        ? 'black'
                                        : 'white'
                                    : isDark
                                    ? 'white'
                                    : 'black',
                            }}
                        >
                            <div className="text-[10px] ">14:00</div>
                            <BellIcon className="h-3" />
                            <CloudIcon className="h-3" />
                            <ChatAlt2Icon className="h-3" />
                        </div>
                        <div className="absolute">
                            <img
                                src={PhoneCamera}
                                alt=""
                                className="w-[18px] h-[18px]"
                            />
                        </div>

                        <div
                            className="flex grow gap-1 justify-end  transition-colors duration-1000"
                            style={{
                                color: allowWhiteSystemUi
                                    ? useBlackTextSystemUiOnLightTheme &&
                                      blackTextSystemUiOnLightTheme &&
                                      !isDark
                                        ? 'black'
                                        : 'white'
                                    : isDark
                                    ? 'white'
                                    : 'black',
                            }}
                        >
                            <WifiIcon className="h-3" />
                            <ChartBarIcon className="h-3" />
                            <ClipboardIcon className="h-3" />
                        </div>
                    </div>
                </div>

                <div className="relative mb-[32px]">
                    <div
                        className="flex gap-2 py-1 px-2 items-center transition-colors  duration-1000"
                        style={{
                            backgroundColor: getColor(
                                colorUsage.background_mini_player
                            ),
                            display: withoutMiniPlayer ? 'none' : 'flex',
                            color: getColor(colorUsage.text_mini_player),
                        }}
                    >
                        <div
                            className="absolute h-[2px] w-3/4 top-0 left-0"
                            style={{
                                backgroundColor: getColor(
                                    colorUsage.color_accent
                                ),
                            }}
                        >
                            <div
                                className="w-2 h-2 rounded-full absolute right-0 -top-[3px] z-[10]"
                                style={{
                                    backgroundColor: getColor(
                                        colorUsage.color_accent
                                    ),
                                }}
                            ></div>
                        </div>
                        <DotsVerticalIcon className="w-4" />
                        <div className="flex-grow leading-3 text-xs select-none">
                            Playing Title
                        </div>
                        <div className="py-[2px]">
                            <IconPlay size="24px" />
                        </div>
                    </div>
                    {!!tabbar && <div className="w-full">{tabbar}</div>}
                </div>

                <div className="absolute bg-black bottom-0  w-full flex justify-around  items-center p-2 transition-colors duration-1000">
                    <AndroidRecentlyAppIcon color={'white'} size="16px" />
                    <AndroidHomeIcon color={'white'} size="16px" />
                    <AndroidBackIcon color={'white'} size="16px" />
                </div>
            </div>
        </div>
    );
};

export default PhoneFrame;
