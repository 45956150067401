import React, { FC, useState } from 'react';
import { EyeIcon } from '@heroicons/react/solid';
import { FormInput, LoginButton } from 'components/Applications/login';
import { LinkText } from 'components';
import { BaseProps } from 'types';
import { TransitionCheckbox } from 'components/Common/CheckBox';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const TAP: FC = () => {
    return (
        <div className="text-[6px] leading-[8px] text-left pl-3">
            By creating an account I agree to application{' '}
            <span>
                <LinkText text="Term Of Services" />
            </span>{' '}
            and confirm I have read application{' '}
            <span>
                <LinkText text="Privacy Policy" />
            </span>
        </div>
    );
};

interface Props extends BaseProps {
    checked?: boolean;
    onChange?: (isChecked: boolean) => void;
}
const Checkbox: FC<Props> = ({ checked, onChange = () => {} }) => {
    const { getColor } = useConfigUsage();
    const onCheckClick = () => onChange(!checked);
    return (
        <div
            className={`w-3 h-3 rounded-sm cursor-pointer 
            transition-all duration-500 overflow-hidden bg-white hover:bg-slate-400 ${
                checked ? 'border-0' : 'border-2'
            }`}
            style={{
                backgroundColor: checked
                    ? getColor(colorUsage.color_accent)
                    : '',
            }}
            onClick={onCheckClick}
        >
            <TransitionCheckbox checked={checked} />
        </div>
    );
};

const RegisterForm: FC = () => {
    const [checked, setChecked] = useState(false);

    return (
        <div className="pt-3 flex flex-col gap-[7px]">
            <div className="text-sm font-bold">Sign Up</div>
            <FormInput hint="Username" />
            <FormInput hint="Name" />
            <FormInput hint="Email" />
            <FormInput hint="Password" icon={<EyeIcon className="w-3" />} />
            <div className="flex items-center">
                <div>
                    <Checkbox
                        checked={checked}
                        onChange={(val) => setChecked(val)}
                    />
                </div>

                <TAP />
            </div>

            <LoginButton
                text="SIGN UP"
                variant="contained"
                className="w-full font-bold mt-1 py-2"
            />
        </div>
    );
};

export default RegisterForm;
