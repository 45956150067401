import React, { FC } from 'react';
import {
    IconPlay,
    IconNext,
    IconPrev,
    IconShuffle,
    IconRepeat,
} from 'assets/icons';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PlayerControl: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className="flex justify-around pt-3 items-center transition-colors duration-1000"
            style={{ color: getColor(colorUsage.icon_1) }}
        >
            <IconShuffle />
            <IconPrev size="24px" />
            <IconPlay size="48px" />
            <IconNext size="24px" />
            <IconRepeat />
        </div>
    );
};

export default PlayerControl;
