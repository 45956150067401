import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface SettingItemProps {
    title?: string;
    subTitle?: string | React.ReactNode;
    titleAsLink?: boolean;
    rightComponent?: React.ReactNode;
}

const SettingItem: FC<SettingItemProps> = ({
    title,
    subTitle,
    titleAsLink,
    rightComponent,
}) => {
    const { getColor } = useConfigUsage();

    return (
        <div
            className="text-[10px] flex items-center transition-colors duration-1000"
            style={{ color: getColor(colorUsage.text_1) }}
        >
            <div className="w-full">
                <div
                    className="font-bold transition-colors duration-1000"
                    style={{
                        color: titleAsLink
                            ? getColor(colorUsage.link_color_visited)
                            : getColor(colorUsage.text_1),
                        cursor: titleAsLink ? 'pointer' : '',
                        textDecoration: titleAsLink ? 'underline' : 'none',
                    }}
                >
                    {title}
                </div>
                <div>{subTitle}</div>
            </div>
            <div>{rightComponent}</div>
        </div>
    );
};

export default SettingItem;
