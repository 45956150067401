import React, { FC } from 'react';
import { IconProps } from './type';

const IconShuffle: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 19 19" fill="none" width={size} height={size}>
            <path
                d="M14.6368 2.0024L17.9368 4.68118C18.0633 4.78298 18.1348 4.93406 18.1348 5.09264C18.1348 5.25123 18.0633 5.40231 17.9368 5.5041L14.6368 8.18288C14.5367 8.26432 14.4113 8.30718 14.2848 8.30718C14.2056 8.30718 14.1253 8.29003 14.0516 8.25682C13.858 8.16895 13.7348 7.9793 13.7348 7.77142V6.16416H12.8141C12.0507 6.16416 11.3533 6.54133 10.9485 7.17138L10.1807 8.36825L8.88382 6.34631L9.08292 6.03557C9.89142 4.7744 11.2862 4.02113 12.8141 4.02113H13.7348V2.41387C13.7348 2.20599 13.858 2.01633 14.0516 1.92954C14.2441 1.84168 14.4729 1.86954 14.6368 2.0024Z"
                fill="currentColor"
            />
            <path
                d="M17.9368 13.2533L14.6368 10.5745C14.4729 10.4406 14.2441 10.4138 14.0516 10.5006C13.858 10.5895 13.7348 10.7781 13.7348 10.986V12.5932H12.8141C12.0507 12.5932 11.3533 12.2161 10.9485 11.586L7.38673 6.03558C6.57823 4.77441 5.18343 4.02114 3.65553 4.02114C3.65553 4.02114 1.06816 4.02081 0.534828 4.02114C0.00149241 4.02147 0.00149354 6.1642 0.534828 6.16416H3.65553C4.41893 6.16416 5.11633 6.54134 5.52113 7.17138L9.08293 12.7218C9.89253 13.983 11.2873 14.7363 12.8141 14.7363H13.7348V16.3435C13.7348 16.5514 13.858 16.74 14.0516 16.8289C14.1253 16.8621 14.2056 16.8793 14.2848 16.8793C14.4113 16.8793 14.5367 16.8375 14.6368 16.755L17.9368 14.0762C18.0633 13.9744 18.1348 13.8233 18.1348 13.6647C18.1348 13.5062 18.0633 13.3551 17.9368 13.2533Z"
                fill="currentColor"
            />
            <path
                d="M6.28893 10.3892L5.52223 11.586C5.11743 12.2161 4.41893 12.5933 3.65553 12.5933H0.534827C0.00149474 12.5933 0.00149354 14.7363 0.534827 14.7363H3.65553C5.18233 14.7363 6.57713 13.983 7.38673 12.7218L7.58583 12.4111L6.28893 10.3892Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconShuffle;
