import React, { FC } from 'react';
import { IconProps } from './type';

const IconPlay: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 67 67" fill="none" width={size} height={size}>
            <path
                d="M28.2394 17.3183L49.7484 30.8702C51.5138 31.9206 51.2086 33.1603 51.2086 33.1603C51.2086 33.1603 51.5137 34.4 49.7482 35.4505L28.2394 49.0023C27.6805 49.335 27.0027 49.3266 26.4418 49.3198C26.3646 49.3188 26.2897 49.3179 26.2176 49.3179C23.8426 49.3179 23.8429 47.4204 23.8429 47.0147V19.3048C23.8428 18.8285 23.8427 17.0028 26.2176 17.0028C26.2899 17.0028 26.3651 17.0019 26.4425 17.0009C27.0032 16.994 27.6806 16.9856 28.2394 17.3183Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.4539 33.1603C66.4539 51.3093 51.7413 66.0219 33.5923 66.0219C15.4434 66.0219 0.730713 51.3093 0.730713 33.1603C0.730713 15.0114 15.4434 0.298706 33.5923 0.298706C51.7413 0.298706 66.4539 15.0114 66.4539 33.1603ZM64.4539 33.1603C64.4539 50.2047 50.6367 64.0219 33.5923 64.0219C16.5479 64.0219 2.73071 50.2047 2.73071 33.1603C2.73071 16.1159 16.5479 2.29871 33.5923 2.29871C50.6367 2.29871 64.4539 16.1159 64.4539 33.1603Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconPlay;
