import React, { FC } from 'react';
import { BaseProps } from 'types';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import {
    UserGroupIcon,
    ViewBoardsIcon,
    HomeIcon,
    SearchIcon,
} from '@heroicons/react/solid';

interface Props extends BaseProps {
    activeAt?: 'home' | 'social' | 'library' | 'search' | 'none';
}
const Tabbar: FC<Props> = ({ className, activeAt = 'none' }) => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className={`z-20 transition-colors duration-1000 ${className}`}
            style={{ backgroundColor: getColor(colorUsage.background_toolbar) }}
        >
            <div
                className={`relative flex justify-around w-full py-2 px-2`}
                style={{ color: getColor(colorUsage.icon_tab) }}
            >
                <HomeIcon
                    className="w-4 transition-colors duration-1000"
                    style={{
                        opacity: activeAt === 'home' ? '1.0' : '0.5',
                    }}
                />
                <UserGroupIcon
                    className="w-4 transition-colors duration-1000"
                    style={{
                        opacity: activeAt === 'social' ? '1.0' : '0.5',
                    }}
                />
                <SearchIcon
                    className="w-4 transition-colors duration-1000"
                    style={{
                        opacity: activeAt === 'search' ? '1.0' : '0.5',
                    }}
                />
                <ViewBoardsIcon
                    className="w-4  justify-between self-end place-self-end transition-colors duration-1000"
                    style={{
                        opacity: activeAt === 'library' ? '1.0' : '0.5',
                    }}
                />
            </div>
        </div>
    );
};

export default Tabbar;
