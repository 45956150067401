import React, { FC } from 'react';
import { PlaylistInfo } from 'components/Applications/playlist';
import image4 from 'assets/images/image-4.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PlaylistHeader: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div className="relative">
            <div
                className="bg-cover bg-center aspect-[1/1] w-full"
                style={{
                    backgroundColor: getColor(colorUsage.background_1),
                    backgroundImage: `url(${image4})`,
                }}
            />
            <div
                className="absolute w-full h-full top-0  transition-all duration-1000"
                style={{
                    backgroundImage: `linear-gradient(to bottom, ${getColor(
                        colorUsage.gradient_end_color
                    )} , ${getColor(colorUsage.gradient_middle_color)})`,
                }}
            ></div>
            <PlaylistInfo />
        </div>
    );
};

export default PlaylistHeader;
