import React, { FC, useState, useEffect, useCallback } from 'react';
import { Input, Select } from 'components';
import { TagResponse } from 'networks/types/TagTypes';
import { getSvaraAndroidTags } from 'networks/services';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';

const SectionVersion: FC = () => {
    const [isFecthingTag, setIsFetchingtag] = useState(false);
    const [tagValue, setTagValue] = useState<TagResponse>({
        name: '',
        message: '',
        commit: {
            committer_name: '',
            created_at: '',
        },
    });
    const [tagOptions, setTagOption] = useState<TagResponse[]>([]);
    const {
        version: { versionCode, versionName, targetSdkVersion, releaseTag },
    } = useSvaraConfig();
    const { changeVersion } = useSvaraConfigAction();

    const onReleaseTagChange = useCallback(
        (data: TagResponse) => {
            changeVersion('releaseTag', data.name);
        },
        [changeVersion]
    );

    useEffect(() => {
        if (tagOptions.length > 0) {
            const tag = tagOptions.find((t) => t.name === releaseTag);
            const created_at = tag?.commit.created_at;
            const event = new Date(created_at ? created_at : 'gagal');
            const options: any = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };

            if (releaseTag.length < 1) {
                changeVersion('releaseTag', tagOptions[0]?.name || '');
            } else if (tag?.name !== tagValue.name) {
                setTagValue({
                    name: tag?.name || '',
                    message: tag?.message || '',
                    commit: {
                        committer_name: tag?.commit.committer_name || '',
                        created_at:
                            event.toLocaleDateString('id-ID', options) || '',
                    },
                });
            }
        }
    }, [releaseTag, tagOptions, tagValue, changeVersion]);

    useEffect(() => {
        const getTag = async () => {
            setIsFetchingtag(true);
            const res = await getSvaraAndroidTags();
            const tag = res.find((res) => res.name === releaseTag);
            const created_at = tag?.commit.created_at;
            const event = new Date(created_at ? created_at : 'gagal');
            const options: any = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };

            setTagOption(res);
            setTagValue({
                name: tag?.name || '',
                message: tag?.message || '',
                commit: {
                    committer_name: tag?.commit.committer_name || '',
                    created_at:
                        event.toLocaleDateString('id-ID', options) || '',
                },
            });
        };
        if (tagOptions.length < 1 && !isFecthingTag) getTag();
    }, [isFecthingTag, tagOptions, releaseTag]);

    const onVersionChange = useCallback(
        (key: string, value: string) => {
            changeVersion(key, value);
        },
        [changeVersion]
    );

    return (
        <div>
            <div className="flex flex-wrap gap-x-16 gap-y-5">
                <Input
                    name="Version Name"
                    width="124px"
                    value={versionName}
                    onChange={(val) => onVersionChange('versionName', val)}
                />
                <Input
                    type="number"
                    name="Version Code"
                    width="124px"
                    value={versionCode}
                    onChange={(val) => onVersionChange('versionCode', val)}
                />
                <Input
                    type="number"
                    name="Target SDK Version"
                    width="124px"
                    value={targetSdkVersion}
                    onChange={(val) => onVersionChange('targetSdkVersion', val)}
                />
            </div>
            <div className="mt-5">
                <Select<TagResponse>
                    width="224px"
                    options={tagOptions}
                    name="Release Tag"
                    value={tagValue}
                    onChange={onReleaseTagChange}
                    getValueString={(val) => val?.name}
                />
            </div>
            <div className="mt-5 grid grid-cols-2 gap-1 justify-evenly">
                <div className="text-base">Release Message</div>
                <div className="text-base justify-self-end">
                    {tagValue.commit.created_at} by{' '}
                    {tagValue.commit.committer_name}
                </div>
                <div className="whitespace-pre-line mt-2 bg-svaraBgGray rounded-xl px-4 py-3 shadow-lg min-h-[124px] col-span-2">
                    {tagValue.message}
                </div>
            </div>
        </div>
    );
};

export default SectionVersion;
