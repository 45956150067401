import React, { FC } from 'react';
import { SectionTitle } from 'components/Applications/sections';
import image2 from 'assets/images/image-2.jpg';
import image5 from 'assets/images/image-5.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { IconPlay } from 'assets/icons';

const SectionStayTune: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div className="p-2 w-full overflow-x-hidden">
            <SectionTitle title="Stay Tune" />

            <div
                className={`flex w-full rounded-md relative overflow-hidden  bg-cover bg-center`}
                style={{ backgroundImage: `url(${image2})` }}
            >
                <div
                    className="absolute h-full w-full z-0 transition-all duration-1000"
                    style={{
                        backgroundImage: `linear-gradient(to right, ${getColor(
                            colorUsage.gradient_card_bg_start_color
                        )}, ${getColor(
                            colorUsage.gradient_card_bg_middle_color
                        )} , ${getColor(
                            colorUsage.gradient_card_bg_end_color
                        )})`,
                    }}
                ></div>
                <div
                    className="flex items-center w-full py-1 px-1 z-10 select-none  relative"
                    style={{ color: getColor(colorUsage.text_4) }}
                >
                    <div
                        className="aspect-[1/1] h-full bg-cover bg-center rounded-md"
                        style={{ backgroundImage: `url(${image5})` }}
                    ></div>

                    <div className="flex flex-col flex-grow px-2">
                        <div className="text-xs font-bold">Svara Radio</div>
                        <div className="text-[10px]">Online Radio</div>
                        <div className="text-[10px]">Pop</div>
                    </div>
                    <div className="p-[2px]">
                        <IconPlay size="24px" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionStayTune;
