import React, { FC } from 'react';
import { Input } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';
import { toStartCase } from 'utils/Text';
import { SvaraConfigAdmob } from 'provider/types';

const SectionAdmob: FC = () => {
    const { admob } = useSvaraConfig();
    const { changeAdmob } = useSvaraConfigAction();

    const onAdmobChange = (key: string, value: string) => {
        changeAdmob(key, value);
    };

    return (
        <div className="flex flex-col gap-x-16 gap-y-5">
            {Object.keys(admob).map((field: string, index: number) => (
                <Input
                    key={index}
                    className="md:w-full lg:w-4/5 xl:w-8/12"
                    name={toStartCase(field)}
                    width="100%"
                    value={admob[field as keyof SvaraConfigAdmob]}
                    onChange={(val) => onAdmobChange(field, val)}
                />
            ))}
        </div>
    );
};

export default SectionAdmob;
