import React, { FC } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { SketchPicker, ColorResult } from 'react-color';

interface PopoverProps {
    show: boolean;
    color: string;
    onClose: () => void;
    onColorChange: (clr: ColorResult) => void;
    onColorChangeComplate: (clr: ColorResult) => void;
}
const PopoverColorPicker: FC<PopoverProps> = ({
    children,
    show,
    onClose,
    onColorChange,
    onColorChangeComplate,
    color,
}) => {
    const ColorPicker = (
        <div className=" bg-black">
            <SketchPicker
                color={color}
                onChange={onColorChange}
                onChangeComplete={onColorChangeComplate}
                disableAlpha={false}
                width="300px"
            />
        </div>
    );

    return (
        <Popover
            isOpen={show}
            positions={['top', 'bottom', 'left', 'right']}
            onClickOutside={onClose}
            boundaryInset={68}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#FFF'}
                    arrowSize={10}
                    arrowStyle={{ opacity: 1 }}
                    className="popover-arrow-container"
                    arrowClassName="popover-arrow"
                >
                    {ColorPicker}
                </ArrowContainer>
            )}
        >
            <div>{children}</div>
        </Popover>
    );
};

export default PopoverColorPicker;
