import React, { FC, useState } from 'react';
import { SettingSection, SettingItem } from 'components/Applications/setting';
import { Switch } from 'components';
import image1 from 'assets/images/image-1.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface Props {
    active?: boolean;
}
const RightSwitch: FC<Props> = ({ active: _active = true }) => {
    const [active, setActive] = useState(_active);
    return (
        <Switch
            size="sm"
            active={active}
            activeText=""
            offText=""
            onChange={(val) => setActive(val)}
        />
    );
};

const SettingPage: FC = () => {
    const { isGradient, getColor } = useConfigUsage();
    return (
        <div
            className="p-2 transition-colors duration-1000"
            style={{
                backgroundColor: getColor(colorUsage.background_1),
                backgroundImage: isGradient
                    ? `linear-gradient(to bottom, ${getColor(
                          colorUsage.background_3
                      )}, ${getColor(colorUsage.background_4)})`
                    : '',
            }}
        >
            <SettingSection title="APPLICATION">
                <SettingItem title="Language" subTitle="English" />
            </SettingSection>
            <SettingSection title="FREE USER">
                <SettingItem
                    title="Pop Up"
                    subTitle={
                        <div className="flex">
                            <div>
                                Always show popup when playing premium content
                                (free user only).
                            </div>
                            <RightSwitch active={false} />
                        </div>
                    }
                />
            </SettingSection>
            <SettingSection title="PLAYER">
                <SettingItem
                    title="Auto Play"
                    subTitle={
                        <div className="flex justify-between">
                            <div>Enable auto play when opening app.</div>
                            <RightSwitch />
                        </div>
                    }
                />
            </SettingSection>
            <SettingSection title="ABOUT APPLICATION">
                <SettingItem title="Version" subTitle="VERSION 1.7.9" />
                <SettingItem
                    title="Term Of Services"
                    subTitle="All the stuff that you need to know."
                    titleAsLink
                />
                <SettingItem
                    title="Privacy Policy"
                    subTitle="Important for both of us."
                    titleAsLink
                />
            </SettingSection>
            <SettingSection title="OTHER">
                <SettingItem
                    title="Log Out"
                    subTitle="You are logged in as username"
                    rightComponent={
                        <div
                            className="bg-gray-500 bg-cover bg-center rounded-full aspect-[1/1] w-6"
                            style={{ backgroundImage: `url(${image1})` }}
                        />
                    }
                />
            </SettingSection>
        </div>
    );
};

export default SettingPage;
