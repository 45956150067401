import React from 'react';
import { svaraDefaultData } from './defaultData';
import { SvaraConfigData, SvaraConfigAction, SvaraConfigUsage } from '../types';

const AppConfigContext = React.createContext<SvaraConfigData>({
    ...svaraDefaultData,
});
const AppConfigActionContext = React.createContext<SvaraConfigAction>({
    changeAdmob: () => {},
    changeAnalytics: () => {},
    changeColor: () => {},
    changeCredentials: () => {},
    changeFacebook: () => {},
    changeGoogle: () => {},
    changeInfo: () => {},
    changeSettings: () => {},
    changeVersion: () => {},
    changeApi: () => {},
});

const AppConfigUsage = React.createContext<SvaraConfigUsage>({
    isDark: false,
    isGradient: false,
    setDark: (isDark: boolean) => {},
    getColor: (name: string) => '',
    hasDarkLightVarian: (name: string) => false,
    fromJson: () => {},
    setGradient: (isGradient: boolean) => {},
});

export { AppConfigContext, AppConfigActionContext, AppConfigUsage };
