import React, { Fragment } from 'react';
import { BaseProps } from 'types';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Scrollbars } from 'react-custom-scrollbars-2';
interface Props<T> extends BaseProps {
    name?: string;
    options: T[];
    value: T;
    width?: string;
    getValueString: (value: T) => string;
    onChange: (value: T) => void;
}
const Select = <T,>({
    name,
    value,
    options,
    width,
    onChange,
    className,
    getValueString,
}: Props<T>) => {
    const handleChange = (event: T) => {
        onChange(event);
    };
    return (
        <div className={className}>
            {!!name && <div className="text-base">{name}</div>}

            <Listbox value={value} onChange={handleChange}>
                <div className={`relative ${!!name && 'mt-2'}`}>
                    <Listbox.Button
                        className={`relative py-0 px-3 pr-10 h-[34px] text-left 
                        bg-white rounded-xl shadow-md cursor-default text-black overflow-hidden`}
                        style={{ width }}
                    >
                        <span className="block truncate">
                            {getValueString(value)}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ChevronDownIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute w-full py-1 mt-1 overflow-hidden text-base 
                        bg-svaraBgGray rounded-xl shadow-lg"
                            style={{ width }}
                        >
                            {options.length > 0 && (
                                <Scrollbars autoHeight>
                                    {options.map((item, index) => (
                                        <Listbox.Option
                                            key={getValueString(item)}
                                            className={({ active }) =>
                                                `${
                                                    active
                                                        ? 'bg-svaraPrimary text-white'
                                                        : 'text-white'
                                                } cursor-default select-none relative py-2 pl-10 pr-4`
                                            }
                                            value={item}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={`${
                                                            selected
                                                                ? 'font-medium'
                                                                : 'font-normal'
                                                        } block truncate`}
                                                    >
                                                        {getValueString(item)}
                                                    </span>
                                                    {selected ? (
                                                        <span
                                                            className={`${
                                                                active
                                                                    ? 'text-white'
                                                                    : 'text-white'
                                                            } absolute inset-y-0 left-0 flex items-center pl-3`}
                                                        >
                                                            <CheckIcon
                                                                className="w-5 h-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Scrollbars>
                            )}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default Select;
