import {
    SvaraConfigData,
    SvaraConfigColorUsage,
    SvaraConfigColor,
} from '../types';
const defaultDataInfo = {
    displayName: '',
    packageName: '',
    packagePath: 'com/desert/strom/mobile/app',
    legalName: '',
    baseLegalUrl: 'https://legal.svara.id/',
    legalWelcome: 'SVARA Platform',
    legalCompany: 'PT. SVARA Inovasi Indonesia',
    legalEmail: 'report@svara.fm',
};

const defaultColor: SvaraConfigColor = {
    light_color_primary: '#5cbc29',
    light_color_accent: '#0fddc7',
    dark_color_primary: '#5cbc29',
    dark_color_accent: '#0fddc7',
    light_fab_text: '#FFFFFF',
    dark_fab_text: '#FFFFFF',
    pinnedBackground: '#41ABDE',
    pinnedText: '#FFFFFF',
    link_color: '#0645AD',
    link_color_visited: '#0645AD',
    login_text: '#5000',
    background_intro_start: '#0D181C',
    background_intro_end: '#0D181C',
    background_intro_bar: '#0D181C',
    text_intro: '#0D181C',
    light_status_bar_background: '#40000000',
    light_status_bar_background_invert: '#40FFFFFF',
    light_background_toolbar: '#8d9a86',
    light_text_toolbar: '#FFFFFF',
    light_icon_toolbar: '#ffffff',
    light_icon_tab: '#FFFFFF',
    light_background_1: '#f5f8f5',
    light_background_2: '#DDDDDD',
    light_background_3: '#BBBBBB',
    light_background_4: '#AAAAAA',
    light_background_5: '#999999',
    light_text_1: '#36432f',
    light_text_2: '#52594e',
    light_text_3: '#333333',
    light_text_4: '#555555',
    light_text_5: '#777777',
    light_background_mini_player: '#626a5d',
    light_text_mini_player: '#FFFFFF',
    light_icon_1: '#36432f',
    light_icon_2: '#333333',
    light_transparent_70: '#50000000',
    light_tab_layout_home: '#a4b39c',
    light_tab_layout_home_text: '#8d9a86',
    light_like_color: '#5cf808',
    light_gradient_start_color: '#FFFFFF',
    light_gradient_middle_color: '#07FEFEFE',
    light_gradient_end_color: '#52FFFFFF',
    light_gradient_card_bg_start_color: '#0D181D',
    light_gradient_card_bg_middle_color: '#CC0D181D',
    light_gradient_card_bg_end_color: '#990D181D',
    light_library_badge_color: '#8d9a86',
    light_library_badge_text_color: '#ffffff',
    light_search_page_field: '#555555',
    light_search_page_text: '#FFFFFF',
    light_search_page_icon: '#AAAAAA',
    light_channel_action_background: '#5cbc29',
    light_channel_action_icon_color: '#FFFFFF',
    dark_status_bar_background: '#40000000',
    dark_status_bar_background_invert: '#40FFFFFF',
    dark_background_toolbar: '#0D181D',
    dark_text_toolbar: '#FFFFFF',
    dark_icon_toolbar: '#FFFFFF',
    dark_icon_tab: '#FFFFFF',
    dark_background_1: '#0D181D',
    dark_background_2: '#1C2930',
    dark_background_3: '#263238',
    dark_background_4: '#303C41',
    dark_background_5: '#3F484B',
    dark_text_1: '#FFFFFF',
    dark_text_2: '#DDDDDD',
    dark_text_3: '#AAAAAA',
    dark_text_4: '#888888',
    dark_text_5: '#666666',
    dark_background_mini_player: '#0D181D',
    dark_text_mini_player: '#FFFFFF',
    dark_icon_1: '#EEEEEE',
    dark_icon_2: '#999999',
    dark_transparent_70: '#50FFFFFF',
    dark_tab_layout_home: '#0D181D',
    dark_tab_layout_home_text: '#FFFFFF',
    dark_like_color: '#5cf808',
    dark_gradient_start_color: '#0D181D',
    dark_gradient_middle_color: '#05000000',
    dark_gradient_end_color: '#12000000',
    dark_gradient_card_bg_start_color: '#FFFFFF',
    dark_gradient_card_bg_middle_color: '#CCFFFFFF',
    dark_gradient_card_bg_end_color: '#99FFFFFF',
    dark_library_badge_color: '#686868',
    dark_library_badge_text_color: '#FFFFFF',
    dark_search_page_field: '#555555',
    dark_search_page_text: '#FFFFFF',
    dark_search_page_icon: '#AAAAAA',
    dark_channel_action_background: '#5cbc29',
    dark_channel_action_icon_color: '#FFFFFF',
};

const svaraDefaultData: SvaraConfigData = {
    ...defaultDataInfo,
    color: defaultColor,
    appCredentials: {
        username: '',
        password: '',
    },
    version: {
        versionName: '1.5.3',
        versionCode: '353',
        releaseTag: '',
        targetSdkVersion: '29',
    },
    analytics: {
        playStats: {
            apiKey: '',
        },
        countly: {
            apiKey: 'e1c280efbe21fa60805233b10c5e3a81d6899d23',
            apiCountly: 'https://countly.svara.id',
        },
    },
    settings: {
        betaVersionLogo: true,
        defaultTheme: 'LIGHT_THEME',
        blackTextSystemUiOnLightTheme: true,
        showSkipButton: true,
        canSignUp: true,
        withFollowFeature: true,
        showAgreementOnRegister: false,
        loginPageUseLogo: true,
        useIntroStart: true,
        showVideoProgress: false,
        useAnimateSplashScreen: true,
        enableToolbarLogo: false,
        useBottomNavigation: false,
        channelPageStyle: 3,
        defaultLanguage: undefined,
        autoSkipLogin: false,
        useChatEngine: false,
        autoPlayStreaming: false,
        videoIntro: false,
        urlVideo: null,
    },
    admob: {
        id: '',
        unitCuration: '',
        unitPlayer: '',
        unitPlayerRadio: '',
        unitQueue: '',
        unitLibrary: '',
        unitRadioPage: '',
    },
    google: {
        webClient: '',
    },
    facebook: {
        facebookAppId: '',
    },
    api: {
        baseUrl: 'https://api.svara.id/v1/',
        baseUrlAds: 'https://api.svara.id/click/v1/',
        baseUrlSocket: 'https://aws-socket.svara.id',
        baseUrlReferral: 'https://api.svara.id/referral/v1/',
        baseUrlAdditionalFeatures:
            'https://api.svara.id/additional-features/v1/',
        baseUrlAnalytics: 'https://api.svara.id/analytics/v1/',
        baseUrlPaymentApi: 'https://api.svara.id/payment/v1/',
        baseUrlPaymentWeb: 'https://payment.svara.fm/',
        baseDomainArticle: 'articles.svara.id',
    },
};

const colorUsage: SvaraConfigColorUsage = {
    color_primary: 'color_primary',
    color_accent: 'color_accent',
    fab_text: 'fab_text',
    pinnedBackground: 'pinnedBackground',
    pinnedText: 'pinnedText',
    link_color: 'link_color',
    link_color_visited: 'link_color_visited',
    login_text: 'login_text',
    background_intro_start: 'background_intro_start',
    background_intro_end: 'background_intro_end',
    background_intro_bar: 'background_intro_bar',
    text_intro: 'text_intro',
    status_bar_background: 'status_bar_background',
    status_bar_background_invert: 'status_bar_background_invert',
    background_toolbar: 'background_toolbar',
    text_toolbar: 'text_toolbar',
    icon_toolbar: 'icon_toolbar',
    icon_tab: 'icon_tab',
    background_1: 'background_1',
    background_2: 'background_2',
    background_3: 'background_3',
    background_4: 'background_4',
    background_5: 'background_5',
    text_1: 'text_1',
    text_2: 'text_2',
    text_3: 'text_3',
    text_4: 'text_4',
    text_5: 'text_5',
    background_mini_player: 'background_mini_player',
    text_mini_player: 'text_mini_player',
    icon_1: 'icon_1',
    icon_2: 'icon_2',
    transparent_70: 'transparent_70',
    tab_layout_home: 'tab_layout_home',
    tab_layout_home_text: 'tab_layout_home_text',
    like_color: 'like_color',
    gradient_start_color: 'gradient_start_color',
    gradient_middle_color: 'gradient_middle_color',
    gradient_end_color: 'gradient_end_color',
    gradient_card_bg_start_color: 'gradient_card_bg_start_color',
    gradient_card_bg_middle_color: 'gradient_card_bg_middle_color',
    gradient_card_bg_end_color: 'gradient_card_bg_end_color',
    library_badge_color: 'library_badge_color',
    library_badge_text_color: 'library_badge_text_color',
    search_page_field: 'search_page_field',
    search_page_text: 'search_page_text',
    search_page_icon: 'search_page_icon',
    channel_action_background: 'channel_action_background',
    channel_action_icon_color: 'channel_action_icon_color',
};

export { svaraDefaultData, defaultDataInfo, colorUsage };
