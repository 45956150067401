import React, { FC } from 'react';
import { useSvaraConfig } from 'provider/hooks/SvaraConfigHook';
import { Disclosure } from '@headlessui/react';
import { DownloadIcon } from '@heroicons/react/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import download from 'utils/download';

const SectionExport: FC = () => {
    const svaraConfig = useSvaraConfig();
    const donwload = () => {
        download(
            JSON.stringify(svaraConfig, null, 4),
            'data-' + svaraConfig.displayName
        );
    };
    return (
        <div className="w-full flex flex-col items-start ">
            <div
                className="bg-svaraPrimary rounded-lg p-3 flex items-center gap-1 
            select-none cursor-pointer transition-all hover:bg-emerald-900 
            hover:shadow-lg hover:scale-110"
                onClick={donwload}
            >
                <DownloadIcon className="h-6" />
                <div> Download</div>
                <div className="bg-slate-600 text-white rounded-md px-1 font-sourceCodePro">
                    data.json
                </div>
            </div>
            <div>
                <Disclosure>
                    {({ open }) => {
                        return (
                            <>
                                <Disclosure.Button className="py-2 flex gap-2 hover:underline">
                                    {open ? (
                                        <ChevronUpIcon className="h-6" />
                                    ) : (
                                        <ChevronDownIcon className="h-6" />
                                    )}
                                    {open ? 'Hide' : 'Show'}
                                    <div className="text-white underline px-1 font-sourceCodePro">
                                        data.json
                                    </div>
                                </Disclosure.Button>
                                <Disclosure.Panel className="bg-svaraBgGray p-3 rounded-lg w-full ">
                                    <pre className="">
                                        {JSON.stringify(svaraConfig, null, 4)}
                                    </pre>
                                </Disclosure.Panel>
                            </>
                        );
                    }}
                </Disclosure>
            </div>
        </div>
    );
    // return <pre className="blo">{JSON.stringify(svaraConfig, null, 4)}</pre>;
};

export default SectionExport;
