import React, { FC } from 'react';
import image3 from 'assets/images/image-3.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PlayerData: FC = () => {
    const { getColor } = useConfigUsage();

    return (
        <div className="pt-5 flex flex-col items-center ">
            <div
                className="bg-gray-500 bg-cover bg-center rounded-[2px] aspect-[1/1] w-11/12 shadow-lg"
                style={{
                    backgroundImage: `url(${image3})`,
                }}
            />
            <div
                className="mt-6 w-full flex gap-3 items-center text-[8px] transition-colors duration-1000"
                style={{
                    color: getColor(colorUsage.text_2),
                }}
            >
                <div>2:30</div>
                <div className="w-full relative bg-[#6E6F4050]">
                    <div
                        className="h-[2px] w-3/4 top-0 transition-colors duration-1000"
                        style={{
                            backgroundColor: getColor(colorUsage.color_accent),
                        }}
                    >
                        <div
                            className="absolute w-2 h-2 rounded-full right-[34px] -top-[3px] z-[10] transition-colors duration-1000"
                            style={{
                                backgroundColor: getColor(
                                    colorUsage.color_accent
                                ),
                            }}
                        ></div>
                    </div>
                </div>

                <div>3:15</div>
            </div>
            <div
                className="pt-3 transition-colors duration-1000"
                style={{ color: getColor(colorUsage.text_1) }}
            >
                <div className="text-[11px] font-bold">Title</div>
                <div className="text-[10px]">Artist</div>
            </div>
        </div>
    );
};

export default PlayerData;
