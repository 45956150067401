import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface BadgeItemProps {
    text?: string;
    selected?: boolean;
}

const BadgeItem: FC<BadgeItemProps> = ({ text, selected }) => {
    const { getColor } = useConfigUsage();

    return (
        <div
            className="w-fit px-1 pt-[1px] rounded-md text-[8px] border-[1px] "
            style={{
                backgroundColor: selected
                    ? 'transparent'
                    : getColor(colorUsage.library_badge_color),
                color: selected
                    ? getColor(colorUsage.color_primary)
                    : getColor(colorUsage.library_badge_text_color),
                borderColor: selected
                    ? getColor(colorUsage.color_primary)
                    : '#00000000',
            }}
        >
            {text}
        </div>
    );
};

export default BadgeItem;
