import React, { FC } from 'react';
import image4 from 'assets/images/image-4.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import {
    DotsHorizontalIcon,
    ShareIcon,
    ChatAlt2Icon,
} from '@heroicons/react/solid';
import { IconPlay, IconLiveVisual, IconMark } from 'assets/icons';

const ChannelHeaderActionBackground: FC = () => {
    const { isGradient, getColor } = useConfigUsage();
    return (
        <div className="relative">
            <div
                className="bg-cover bg-center aspect-[1/1] w-full"
                style={{
                    backgroundColor: getColor(colorUsage.background_1),
                    backgroundImage: `url(${image4})`,
                }}
            />
            <div
                className="absolute w-full h-full top-0  transition-all duration-1000"
                style={{
                    backgroundImage: `linear-gradient(to bottom, ${getColor(
                        colorUsage.gradient_end_color
                    )} , ${getColor(colorUsage.gradient_middle_color)})`,
                }}
            ></div>
            <div className="p-2 absolute pb-4 bottom-0 w-full">
                <div
                    className="absolute h-full w-full top-0 left-0 z-0 opacity-80  transition-colors duration-1000"
                    style={{
                        backgroundColor: getColor(
                            isGradient
                                ? colorUsage.background_3
                                : colorUsage.background_1
                        ),
                    }}
                ></div>
                <div className="flex justify-between z-[1] relative w-full">
                    <div
                        className="text-[10px] font-bold transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_1) }}
                    >
                        Svara Radio - bandung
                    </div>
                    <div
                        className="flex gap-2 transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.icon_1) }}
                    >
                        <DotsHorizontalIcon className="w-4" />
                    </div>
                </div>
                <div className="flex gap-2 pt-px z-[1] relative">
                    <div className="w-full px-1">
                        <div
                            className="flex px-3 py-[2px] mt-2 justify-between items-center w-full transition-colors duration-1000 rounded-md"
                            style={{
                                color: getColor(
                                    colorUsage.channel_action_icon_color
                                ),
                                backgroundColor: getColor(
                                    colorUsage.channel_action_background
                                ),
                            }}
                        >
                            <ShareIcon className="w-4" />
                            <ChatAlt2Icon className="w-5" />
                            <IconPlay size="20px" />
                            <IconLiveVisual size="20px" />
                            <IconMark size="16px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChannelHeaderActionBackground;
