import React, { FC } from 'react';
import menuMusic from 'assets/images/menu_music.jpg';
import menuPodcast from 'assets/images/menu_podcast.jpg';
import menuRadio from 'assets/images/menu_radio.jpg';
import menuVideo from 'assets/images/menu_video.jpg';

const SectionMenu: FC = () => {
    return (
        <div className="p-2 w-full overflow-x-hidden">
            <div className="flex gap-2 w-full">
                <div
                    className="bg-gray-500 bg-cover bg-center rounded-md aspect-[16/9] w-1/4"
                    style={{ backgroundImage: `url(${menuRadio})` }}
                ></div>
                <div
                    className="bg-gray-500 bg-cover bg-center rounded-md aspect-[16/9] w-1/4"
                    style={{ backgroundImage: `url(${menuMusic})` }}
                ></div>
                <div
                    className="bg-gray-500 bg-cover bg-center rounded-md aspect-[16/9] w-1/4"
                    style={{ backgroundImage: `url(${menuPodcast})` }}
                ></div>
                <div
                    className="bg-gray-500 bg-cover bg-center rounded-md aspect-[16/9] w-1/4"
                    style={{ backgroundImage: `url(${menuVideo})` }}
                ></div>
            </div>
        </div>
    );
};

export default SectionMenu;
