import React, { FC } from 'react';
import image1 from 'assets/images/image-1.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const ChatItem: FC = () => {
    const { getColor } = useConfigUsage();

    return (
        <div className="flex gap-2">
            <div
                className=" bg-cover bg-center aspect-[1/1] rounded-full w-6 h-6"
                style={{ backgroundImage: `url(${image1})` }}
            />
            <div>
                <div
                    className="text-[8px]"
                    style={{ color: getColor(colorUsage.text_2) }}
                >
                    28d
                </div>
                <div className="flex items-center gap-3">
                    <div
                        className="text-[10px]"
                        style={{ color: getColor(colorUsage.text_1) }}
                    >
                        First Last Name
                    </div>
                    <div
                        className="text-[8px]"
                        style={{ color: getColor(colorUsage.text_1) + '60' }}
                    >
                        @username
                    </div>
                </div>
                <div
                    className="text-[8px]"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                </div>
                <div className="text-[8px] text-gray-400">Reply</div>
            </div>
        </div>
    );
};

export default ChatItem;
