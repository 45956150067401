import React, { FC } from 'react';
import { HeartIcon, DotsHorizontalIcon } from '@heroicons/react/solid';
import image6 from 'assets/images/image-6.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PlaylistInfo: FC = () => {
    const { isGradient, getColor } = useConfigUsage();
    return (
        <div className="p-2 absolute pb-7 bottom-0 w-full">
            <div
                className="absolute h-full w-full top-0 left-0 z-0 opacity-80  transition-colors duration-1000"
                style={{
                    backgroundColor: getColor(
                        isGradient
                            ? colorUsage.background_3
                            : colorUsage.background_1
                    ),
                }}
            ></div>
            <div className="flex justify-between z-[1] relative w-full">
                <div
                    className="text-sm font-bold transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    Playlist title
                </div>
                <div
                    className="flex gap-2 transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.icon_1) }}
                >
                    <HeartIcon className="w-4" />
                    <DotsHorizontalIcon className="w-4" />
                </div>
            </div>
            <div className="flex gap-2 pt-1 z-[1] relative">
                <div
                    className="bg-gray-500 bg-cover bg-center rounded-full aspect-[1/1] w-5 h-5"
                    style={{ backgroundImage: `url(${image6})` }}
                />
                <div>
                    <div
                        className="text-xs transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.color_primary) }}
                    >
                        First Last Name
                    </div>
                    <div
                        className="text-[8px] transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_1) }}
                    >
                        Playlist description.
                        <br />
                        <span
                            className="transition-colors duration-1000"
                            style={{
                                color: getColor(colorUsage.color_primary),
                            }}
                        >
                            show more.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlaylistInfo;
