import { TagResponse } from 'networks/types/TagTypes';
const getSvaraAndroidTags = async (): Promise<TagResponse[]> => {
    const res = await fetch(
        `${process.env.REACT_APP_SVARA_ANDROID_REPO}api/v4/projects/${process.env.REACT_APP_SVARA_ANDROID_REPO_ID}/repository/tags`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_GITLAB_ACCESS_TOKEN}`,
            },
        }
    );
    return await res.json();
};

export { getSvaraAndroidTags };
