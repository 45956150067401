import React from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import { Toolbar, Tabbar } from 'components/Applications';
import { SocialItem } from 'components/Applications/social';
import {
    VideoCameraIcon,
    CollectionIcon,
    ClipboardListIcon,
    MicrophoneIcon,
    PlusIcon,
} from '@heroicons/react/solid';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { createContentColorsItems } from 'utils/Colors';
import { ContentColorsItem } from 'components/AppPage/types';

const socialColor = [
    colorUsage.color_primary,
    colorUsage.color_accent,
    colorUsage.fab_text,
    colorUsage.background_1,
    colorUsage.background_3,
    colorUsage.background_4,
    colorUsage.text_1,
    colorUsage.text_2,
    colorUsage.text_3,
    colorUsage.icon_1,
    colorUsage.like_color,
    colorUsage.background_toolbar,
    colorUsage.text_toolbar,
    colorUsage.icon_toolbar,
    colorUsage.tab_layout_home,
    colorUsage.tab_layout_home_text,
    colorUsage.background_mini_player,
    colorUsage.text_mini_player,
];

const Bubble: React.FC = ({ children }) => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className="rounded-2xl flex items-center gap-1 px-2 py-1 text-xs transition-colors duration-1000"
            style={{
                backgroundColor: getColor(colorUsage.color_accent),
                color: getColor(colorUsage.fab_text),
            }}
        >
            {children}
        </div>
    );
};

const Social: React.FC = () => {
    const phoneWidth = '236px';
    const { isDark, setDark, getColor, hasDarkLightVarian } = useConfigUsage();

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            socialColor,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            toolbar={<Toolbar title="SOCIAL" />}
                            tabbar={<Tabbar activeAt="social" />}
                        >
                            <div className="transition-colors duration-1000">
                                <SocialItem />
                                <SocialItem />
                                <SocialItem />
                                <SocialItem />
                            </div>
                        </PhoneFrame>
                        <div className="rounded-full absolute bottom-36 right-2 flex flex-col items-end pr-2 gap-1">
                            <Bubble>
                                <VideoCameraIcon className="h-4" />
                                Video
                            </Bubble>
                            <Bubble>
                                <CollectionIcon className="h-4" />
                                Series
                            </Bubble>
                            <Bubble>
                                <ClipboardListIcon className="h-4" />
                                Playlist
                            </Bubble>
                            <Bubble>
                                <MicrophoneIcon className="h-4" />
                                Podcast
                            </Bubble>
                            <div
                                className={`rounded-full p-3 flex items-center transition-colors duration-1000`}
                                style={{
                                    backgroundColor: getColor(
                                        colorUsage.color_accent
                                    ),
                                    color: getColor(colorUsage.fab_text),
                                }}
                            >
                                <PlusIcon className="h-4" />
                            </div>
                        </div>

                        <Switch
                            active={isDark}
                            onChange={setDark}
                            activeText="Dark"
                            offText="Light"
                            removeColorActive={false}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Social;
