import React, { FC } from 'react';
import { BaseProps } from 'types';
import SvaraLogo from 'assets/svara.svg';
import { MenuItem } from 'components/Common/Toolbar';
import { MenuList } from 'constants/Menu';
const Toolbar: FC<BaseProps> = ({ className }) => {
    return (
        <div
            className={`bg-svaraBackground h-toolbar shadow-md sticky top-0 z-30 ${className}`}
        >
            <div className="flex h-full justify-start content-center items-center p-4 gap-6">
                <img src={SvaraLogo} className="h-9" alt="Svara" />
                <div className="flex justify-start gap-3 text-white">
                    {MenuList.map((menu) => (
                        <MenuItem
                            key={menu.name}
                            name={menu.name}
                            link={menu.link}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Toolbar;
