import React, { FC } from 'react';
import { EyeIcon } from '@heroicons/react/solid';
import { LoginButton, FormInput } from 'components/Applications/login';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const LoginForm: FC = () => {
    const { getColor } = useConfigUsage();

    return (
        <div className="pt-3 flex flex-col gap-3">
            <div className="text-sm font-bold">Sign In</div>
            <FormInput text="support@svara.id" hint="username / email" />
            <FormInput hint="Password" icon={<EyeIcon className="w-3" />} />
            <LoginButton
                text="SIGN IN"
                variant="contained"
                className="w-full font-bold mt-2 py-2"
            />
            <div
                className="text-[12px]"
                style={{ color: getColor(colorUsage.text_1) }}
            >
                Forgot Password
            </div>
        </div>
    );
};

export default LoginForm;
