import React, { useState } from 'react';
import {
    AppConfigContext,
    AppConfigActionContext,
    AppConfigUsage,
} from './context/SvaraConfigContext';
import {
    SvaraConfigInfo,
    SvaraConfigColor,
    SvaraConfigCredentials,
    SvaraConfigVersion,
    SvaraConfigAnalytics,
    SvaraConfigSettings,
    SvaraConfigAdmob,
    SvaraConfigGoogle,
    SvaraConfigFacebook,
    SvaraConfigApi,
} from './types';
import { svaraDefaultData, defaultDataInfo } from './context/defaultData';
import { fromAndroidHex, toAndroidHex } from 'utils/Colors';
import set from 'lodash/set';

const SvaraAppProvider: React.FC = ({ children }) => {
    const [isDark, setDark] = useState(false);
    const [isGradient, setGradient] = useState(false);

    const [configInfo, setCofigInfo] = useState<SvaraConfigInfo>({
        ...defaultDataInfo,
    });

    const [configColor, setConfigColor] = useState<SvaraConfigColor>(
        svaraDefaultData.color
    );

    const [configCredential, setConfigCredential] =
        useState<SvaraConfigCredentials>(svaraDefaultData.appCredentials);

    const [configVersion, setConfigVersion] = useState<SvaraConfigVersion>(
        svaraDefaultData.version
    );

    const [configAnalytis, setConfigAnalytis] = useState<SvaraConfigAnalytics>(
        svaraDefaultData.analytics
    );

    const [configSettings, setConfigSettings] = useState<SvaraConfigSettings>(
        svaraDefaultData.settings
    );

    const [configAdmob, setConfigAdmob] = useState<SvaraConfigAdmob>(
        svaraDefaultData.admob
    );

    const [configGoogle, setConfigGoogle] = useState<SvaraConfigGoogle>(
        svaraDefaultData.google
    );

    const [configFacebook, setConfigFacebook] = useState<SvaraConfigFacebook>(
        svaraDefaultData.facebook
    );

    const [configApi, setConfigApi] = useState<SvaraConfigApi>(
        svaraDefaultData.api
    );

    const changeInfo = (key: string, value: string) => {
        setCofigInfo((prev) => ({ ...prev, [key]: value }));
    };

    const changeColor = (
        key: string,
        value: string,
        isAndroidHex: boolean = false
    ) => {
        setConfigColor((prev) => ({
            ...prev,
            [key]: isAndroidHex ? value : toAndroidHex(value),
        }));
    };

    const changeCredentials = (key: string, value: string) => {
        setConfigCredential((prev) => ({ ...prev, [key]: value }));
    };
    const changeVersion = (key: string, value: string) => {
        setConfigVersion((prev) => ({ ...prev, [key]: value }));
    };
    const changeAnalytics = (key: string, value: string) => {
        const newData = set(configAnalytis, key, value);

        setConfigAnalytis(() => ({ ...newData }));
    };
    const changeSettings = (
        key: string,
        value: string | number | boolean | undefined | null
    ) => {
        setConfigSettings((prev) => ({ ...prev, [key]: value }));
    };
    const changeAdmob = (key: string, value: string) => {
        setConfigAdmob((prev) => ({ ...prev, [key]: value }));
    };
    const changeGoogle = (key: string, value: string) => {
        setConfigGoogle((prev) => ({ ...prev, [key]: value }));
    };
    const changeFacebook = (key: string, value: string) => {
        setConfigFacebook((prev) => ({ ...prev, [key]: value }));
    };
    const changeApi = (key: string, value: string) => {
        setConfigApi((prev) => ({ ...prev, [key]: value }));
    };

    const onDarkChange = (val: boolean) => {
        setDark(val);
    };

    const hasDarkLightVarian = (val: string): boolean => {
        const _color: any = { ...configColor } as object;
        return !_color[val];
    };

    const getColor = (name: string): string => {
        let _key = name.replace('dark_', '').replace('light_', '');
        _key = isDark ? 'dark_' + _key : 'light_' + _key;
        const _color: any = { ...configColor } as object;
        let realColor = _color[_key] || _color[name];
        return fromAndroidHex(realColor);
    };

    const fromJson = (val: { [key: string]: any }) => {
        Object.entries(defaultDataInfo).forEach(([key, v]) => {
            if (val.hasOwnProperty(key)) changeInfo(key, val[key]);
        });

        if (val.hasOwnProperty('color')) {
            Object.entries(svaraDefaultData.color).forEach(([key, v]) => {
                if (val.color.hasOwnProperty(key))
                    changeColor(key, val.color[key], true);
            });
        }
        if (val.hasOwnProperty('appCredentials')) {
            Object.entries(svaraDefaultData.appCredentials).forEach(
                ([key, v]) => {
                    if (val.appCredentials.hasOwnProperty(key))
                        changeCredentials(key, val.appCredentials[key]);
                }
            );
        }
        if (val.hasOwnProperty('version')) {
            Object.entries(svaraDefaultData.version).forEach(([key, v]) => {
                if (val.version.hasOwnProperty(key))
                    changeVersion(key, val.version[key]);
            });
        }
        if (val.hasOwnProperty('analytics')) {
            const playStatsApi = val?.analytics?.playStats?.apiKey;
            if (playStatsApi) changeAnalytics('playStats.apiKey', playStatsApi);

            const countlyApiKey = val?.analytics?.countly?.apiKey;
            if (countlyApiKey) changeAnalytics('countly.apiKey', countlyApiKey);
            const countlyApiCountly = val?.analytics?.countly?.apiCountly;
            if (countlyApiCountly)
                changeAnalytics('countly.apiCountly', countlyApiCountly);
        }

        if (val.hasOwnProperty('settings')) {
            Object.entries(svaraDefaultData.settings).forEach(([key, v]) => {
                const a = val.settings[key];
                console.log('setting: ', key, ' >> ', a);
                if (val.settings.hasOwnProperty(key))
                    changeSettings(key, val.settings[key]);
            });
        }

        if (val.hasOwnProperty('admob')) {
            Object.entries(svaraDefaultData.admob).forEach(([key, v]) => {
                if (val.admob.hasOwnProperty(key))
                    changeAdmob(key, val.admob[key]);
            });
        }

        if (val.hasOwnProperty('google')) {
            Object.entries(svaraDefaultData.google).forEach(([key, v]) => {
                if (val.google.hasOwnProperty(key))
                    changeGoogle(key, val.google[key]);
            });
        }

        if (val.hasOwnProperty('facebook')) {
            Object.entries(svaraDefaultData.facebook).forEach(([key, v]) => {
                if (val.facebook.hasOwnProperty(key))
                    changeFacebook(key, val.facebook[key]);
            });
        }

        if (val.hasOwnProperty('api')) {
            Object.entries(svaraDefaultData.api).forEach(([key, v]) => {
                if (val.api.hasOwnProperty(key)) changeApi(key, val.api[key]);
            });
        }
    };

    const onGradientChange = (val: boolean) => {
        setGradient(val);
    };

    return (
        <AppConfigContext.Provider
            value={{
                ...configInfo,
                color: configColor,
                appCredentials: configCredential,
                version: configVersion,
                analytics: configAnalytis,
                settings: configSettings,
                admob: configAdmob,
                google: configGoogle,
                facebook: configFacebook,
                api: configApi,
            }}
        >
            <AppConfigActionContext.Provider
                value={{
                    changeInfo: changeInfo,
                    changeColor,
                    changeCredentials,
                    changeVersion,
                    changeAnalytics,
                    changeSettings,
                    changeAdmob,
                    changeGoogle,
                    changeFacebook,
                    changeApi,
                }}
            >
                <AppConfigUsage.Provider
                    value={{
                        isGradient,
                        isDark,
                        setDark: onDarkChange,
                        getColor: getColor,
                        hasDarkLightVarian,
                        fromJson,
                        setGradient: onGradientChange,
                    }}
                >
                    {children}
                </AppConfigUsage.Provider>
            </AppConfigActionContext.Provider>
        </AppConfigContext.Provider>
    );
};

export default SvaraAppProvider;
