import React, {
    FC,
    useState,
    SyntheticEvent,
    useRef,
    KeyboardEventHandler,
} from 'react';
import { BaseProps } from 'types';

interface Props extends BaseProps {
    onValueChange: (values: string[]) => void;
    name?: string;
    values: string[];
    placeholder?: string;
    spacing?: string;
    showInfo?: boolean;
    width?: string;
}

interface TagItemProps {
    value: string;
    index: number;
    onChange: (val: string, index: number) => void;
    onRemove: (index: number) => void;
}

const TagItem: FC<TagItemProps> = ({ value, index, onChange, onRemove }) => {
    const [isEdit, setEdit] = useState(false);
    const [val, setVal] = useState(value);

    const input = useRef<HTMLInputElement>(null);

    const onTagClick = (e: SyntheticEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setEdit(true);
        setTimeout(() => {
            input.current?.focus();
        }, 100);
    };

    const onTagBlur = () => {
        setEdit(false);
        setVal(value);
    };

    const onInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
        setVal(event.currentTarget.value.replace(' ', ''));
    };

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (val.replace(' ', '').length === 0) setVal(value);
                else onChange(val, index);
                event.preventDefault();
                break;
            case 'Backspace':
                if (val.replace(' ', '').length < 1) {
                    onRemove(index);
                }
                break;
        }
    };

    return (
        <div
            className="bg-svaraPrimary font-sourceCodePro rounded-lg px-1 py-[2px] h-7 cursor-pointer"
            onClick={onTagClick}
        >
            {!isEdit && value}
            <input
                ref={input}
                className={`text-white font-sourceCodePro appearance-none outline-none bg-transparent ${
                    isEdit ? '' : 'hidden'
                }`}
                style={{ width: Math.max(val.length, 1) * 10 }}
                onChange={onInputChange}
                onKeyDown={onKeyDown}
                onBlur={onTagBlur}
                value={val}
            />
        </div>
    );
};

const InputTag: FC<Props> = ({
    onValueChange,
    name,
    values,
    placeholder,
    spacing,
    showInfo,
    className,
    width = '664px',
}) => {
    const [text, setText] = useState('');
    const input = useRef<HTMLInputElement>(null);

    const onChange = (event: SyntheticEvent<HTMLInputElement>) => {
        setText(event.currentTarget.value.replace(' ', ''));
    };

    const onContainerClick = () => {
        if (!input) return;
        input.current?.focus();
    };

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (text.replace(' ', '').length > 0) {
                    onValueChange([...values, text]);
                    setText('');
                }
                event.preventDefault();
                break;
            case 'Backspace':
                if (text.replace(' ', '').length < 1) {
                    onValueChange([...values].slice(0, -1));
                    event.preventDefault();
                }
                break;
        }
    };

    const onTagItemChange = (val: string, index: number) => {
        const _values = [...values];
        _values[index] = val;
        onValueChange(_values);
        input.current?.focus();
    };

    const onItemTagRemove = (index: number) => {
        const _values = [...values];
        _values.splice(index, 1);
        onValueChange(_values);
        input.current?.focus();
    };

    return (
        <div className={className}>
            {!!name && <div className="text-base">{name}</div>}
            <div
                className={`font-sourceCodePro bg-white rounded-xl 
                min-h-[34px] px-3 shadow-md flex justify-start 
                items-center flex-wrap gap-1 cursor-text relative ${
                    !!name && 'mt-2'
                }`}
                style={{ width }}
                onClick={onContainerClick}
            >
                {values.map((val, i) => {
                    if (!!spacing && i !== 0)
                        return (
                            <React.Fragment key={val + i}>
                                <div className="text-black">{spacing}</div>
                                <TagItem
                                    value={val}
                                    index={i}
                                    onChange={onTagItemChange}
                                    onRemove={onItemTagRemove}
                                />
                            </React.Fragment>
                        );

                    return (
                        <TagItem
                            key={val + 1}
                            value={val}
                            index={i}
                            onChange={onTagItemChange}
                            onRemove={onItemTagRemove}
                        />
                    );
                })}

                <input
                    ref={input}
                    className="text-black appearance-none outline-none bg-transparent"
                    style={{ width: Math.max(text.length, 1) * 10 }}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={text}
                />
                {values.length === 0 && text.length === 0 && (
                    <div className="absolute font-nunito text-gray-400 w-[calc(100%-24px)] whitespace-nowrap overflow-hidden">
                        {placeholder}
                    </div>
                )}
            </div>
            {showInfo && (
                <div className="text-xs pl-2 pt-1">
                    Type a text and press enter / tab to add.
                </div>
            )}
        </div>
    );
};

export default InputTag;
