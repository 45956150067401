import React, { FC } from 'react';
import { BaseProps } from 'types';
import { Link, useMatch } from 'react-router-dom';

interface MenuItemProps extends BaseProps {
    name: string;
    link?: string;
}

const MenuItem: FC<MenuItemProps> = ({ name, link }) => {
    const active = useMatch({ path: link || '', end: false });
    return (
        <Link className="relative" to={`${link}` || '/'}>
            <div
                className={`after:block after:transition-all after:w-0 after:bg-white cursor-pointer after:h-[3px] after:absolute ${
                    active ? 'after:w-full' : 'hover:after:w-full'
                }`}
            >
                {name}
            </div>
        </Link>
    );
};

export default MenuItem;
