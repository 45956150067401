import React, { FC, useEffect, useState } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface FormInputProps {
    text?: string;
    hint?: string;
    icon?: React.ReactNode;
    width?: string;
}

const FormInput: FC<FormInputProps> = ({
    text,
    hint,
    icon,
    width = '100%',
}) => {
    const [_text, setText] = useState(text);
    const [focus, setFocus] = useState(false);
    const { getColor, setDark } = useConfigUsage();

    useEffect(() => {
        setDark(false);
    }, [setDark]);

    const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    };

    const onFocus = () => setFocus(true);

    const onBlur = () => setFocus(false);

    return (
        <div
            className={`px-3 border-[1px]  rounded-[8px] w-full flex justify-between items-center `}
            style={{
                borderColor: getColor(
                    focus ? colorUsage.color_primary : colorUsage.text_1
                ),
            }}
        >
            <input
                className={`rounded-xl h-[28px] text-[10px] appearance-none outline-none bg-transparent`}
                type="text"
                style={{ width, color: getColor(colorUsage.text_1) }}
                placeholder={hint}
                onChange={onChange}
                value={_text}
                onFocus={onFocus}
                onBlur={onBlur}
            />

            <div style={{ color: getColor(colorUsage.text_1) }}>{icon}</div>
        </div>
    );
};

export default FormInput;
