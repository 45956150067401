import React, { FC } from 'react';
import { BaseProps } from 'types';

interface Props extends BaseProps {
    text: string;
}
const Title: FC<Props> = ({ text, className }) => {
    return (
        <div className={`text-[26px] leading-[34px] font-bold ${className}`}>
            {text}
        </div>
    );
};

export default Title;
