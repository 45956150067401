import React, { FC } from 'react';

interface Props {
    title: string;
}
const SectionTitle: FC<Props> = ({ title }) => {
    return (
        <div className="text-base font-bold transition-colors duration-1000 select-none">
            {title}
        </div>
    );
};

export default SectionTitle;
