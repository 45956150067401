import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface SettingSectionProps {
    title?: string;
    children?: React.ReactNode;
}

const SettingSection: FC<SettingSectionProps> = ({ title, children }) => {
    const { getColor } = useConfigUsage();
    return (
        <div className="mt-2">
            <div
                className="text-[10px] transition-colors duration-1000"
                style={{ color: getColor(colorUsage.text_1) }}
            >
                {title}
            </div>
            <div
                className="w-full h-px mb-2 transition-colors duration-1000"
                style={{ backgroundColor: getColor(colorUsage.text_3) }}
            ></div>
            <div className="flex flex-col gap-1">{children}</div>
        </div>
    );
};

export default SettingSection;
