import React, { FC } from 'react';
import { AppPagelayout } from 'layout';
import { SideBarItem } from 'components/ApplicationSidebar';
import { Outlet } from 'react-router-dom';
import appMenu from './constants/AppMenu';

const Application: FC = () => {
    return (
        <AppPagelayout>
            <div className="flex overflow-x-hidden">
                <div className="flex-shrink-0  w-[186px] content-full-height bg-svaraBgGray">
                    <div className="flex flex-col gap-4 p-4 fixed">
                        {appMenu.map((menu) => {
                            if (menu.name === 'divider')
                                return (
                                    <div
                                        key={menu.name}
                                        className="bg-white h-px shadow-md w-[186px] -ml-4 my-3"
                                    ></div>
                                );
                            return (
                                <SideBarItem
                                    key={menu.name}
                                    name={menu.name}
                                    link={menu.path}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="flex-grow p-6">
                    <Outlet />
                </div>
            </div>
        </AppPagelayout>
    );
};

export default Application;
