import React, { FC } from 'react';
import { IconProps } from './type';

const IconLiveVisual: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 33 33" fill="none" width={size} height={size}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.77143 0H25.6286C27.7029 0 29.4 1.65 29.4 3.66667V33L16.2 27.5L3 33L3.01886 3.66667C3.01886 1.65 4.69714 0 6.77143 0ZM16.2 23.5033L25.6286 27.5V8.66088C25.6286 3.66667 25.6286 3.66667 20.7183 3.66667H11.6817C6.77143 3.66667 6.77143 3.66667 6.77143 8.66088V27.5L16.2 23.5033Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconLiveVisual;
