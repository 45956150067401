import React, { FC } from 'react';
import { IconProps } from './type';

const IconAlbum: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 16 16" fill="none" width={size} height={size}>
            <path
                d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM10 8C10 7.46957 9.78929 6.96086 9.41421 6.58579C9.03914 6.21071 8.53043 6 8 6C7.46957 6 6.96086 6.21071 6.58579 6.58579C6.21071 6.96086 6 7.46957 6 8C6 8.53043 6.21071 9.03914 6.58579 9.41421C6.96086 9.78929 7.46957 10 8 10C8.53043 10 9.03914 9.78929 9.41421 9.41421C9.78929 9.03914 10 8.53043 10 8ZM4 8C4 6.93913 4.42143 5.92172 5.17157 5.17157C5.92172 4.42143 6.93913 4 8 4C8.13261 4 8.25979 3.94732 8.35355 3.85355C8.44732 3.75979 8.5 3.63261 8.5 3.5C8.5 3.36739 8.44732 3.24021 8.35355 3.14645C8.25979 3.05268 8.13261 3 8 3C6.67392 3 5.40215 3.52678 4.46447 4.46447C3.52678 5.40215 3 6.67392 3 8C3 8.13261 3.05268 8.25979 3.14645 8.35355C3.24021 8.44732 3.36739 8.5 3.5 8.5C3.63261 8.5 3.75979 8.44732 3.85355 8.35355C3.94732 8.25979 4 8.13261 4 8ZM13 8C13 7.86739 12.9473 7.74021 12.8536 7.64645C12.7598 7.55268 12.6326 7.5 12.5 7.5C12.3674 7.5 12.2402 7.55268 12.1464 7.64645C12.0527 7.74021 12 7.86739 12 8C12 9.06087 11.5786 10.0783 10.8284 10.8284C10.0783 11.5786 9.06087 12 8 12C7.86739 12 7.74021 12.0527 7.64645 12.1464C7.55268 12.2402 7.5 12.3674 7.5 12.5C7.5 12.6326 7.55268 12.7598 7.64645 12.8536C7.74021 12.9473 7.86739 13 8 13C9.32608 13 10.5979 12.4732 11.5355 11.5355C12.4732 10.5979 13 9.32608 13 8Z"
                fill={color}
            />
        </svg>
    );
};

export default IconAlbum;
