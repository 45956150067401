import React, { FC } from 'react';
import { LoginLogo } from 'assets/icons';
import { useSvaraConfig } from 'provider/hooks/SvaraConfigHook';

const LoginHeader: FC = () => {
    const {
        settings: { loginPageUseLogo },
    } = useSvaraConfig();
    return (
        <div className="flex flex-col">
            <div className="text-right text-[8px]">Skip</div>

            <div className="self-center">
                {loginPageUseLogo ? (
                    <LoginLogo size="48px" color="#225B76" />
                ) : (
                    <div className="h-12"></div>
                )}
            </div>
        </div>
    );
};

export default LoginHeader;
