import React, { FC } from 'react';
import { BadgeBar } from 'components/Applications/library';

const LibraryHeader: FC = () => {
    return (
        <div className="p-1 flex flex-col gap-1">
            <BadgeBar
                title="Contents"
                contents={['Offline Data', 'Private Room']}
            />
            <BadgeBar
                title="Your Library"
                contents={[
                    'Playlist',
                    'Music',
                    'Artist',
                    'Album',
                    'Radio',
                    'Podcast',
                    'Series',
                ]}
                selectedIndex={0}
            />
        </div>
    );
};

export default LibraryHeader;
