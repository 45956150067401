import React, { FC, useEffect } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import './TextBox.scss';

const TextBox: FC = () => {
    const { getColor } = useConfigUsage();
    const text_1 = getColor(colorUsage.text_1);

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty('--color', text_1);
    }, [text_1]);

    return (
        <div
            className="flex justify-between p-2"
            style={{ background: getColor(colorUsage.transparent_70) }}
        >
            <input className="inputStyled" placeholder="type a message..." />
            <PaperAirplaneIcon
                className="w-4 origin-center rotate-90"
                style={{ color: getColor(colorUsage.icon_1) }}
            />
        </div>
    );
};

export default TextBox;
