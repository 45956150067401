import React, { FC } from 'react';
import image2 from 'assets/images/image-2.jpg';
import { IconPlay } from 'assets/icons';
import { colorUsage } from 'provider/context/defaultData';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';

const ItemContent: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div className="w-full pt-1">
            <div className="flex gap-2">
                <div
                    className="bg-gray-500 bg-cover bg-center rounded aspect-[1/1] w-14 h-14 transition-colors duration-1000"
                    style={{ backgroundImage: `url(${image2})` }}
                />
                <div className="flex-grow">
                    <div
                        className="text-[10px] font-bold transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_1) }}
                    >
                        Content Title
                    </div>
                    <div
                        className="text-[8px] transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_2) }}
                    >
                        Content owner
                    </div>
                    <div
                        className="text-[8px] transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_3) }}
                    >
                        Content description
                    </div>
                </div>
                <div
                    className="flex items-center transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.icon_1) }}
                >
                    <IconPlay size="24px" />
                </div>
            </div>
            <div
                className="pt-1 text-[10px] transition-colors duration-1000"
                style={{ color: getColor(colorUsage.text_2) }}
            >
                Caption. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
            </div>
        </div>
    );
};

export default ItemContent;
