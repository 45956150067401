import React, { FC } from 'react';
import { Input } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';

const SectionCredentials: FC = () => {
    const {
        appCredentials: { username, password },
    } = useSvaraConfig();
    const { changeCredentials } = useSvaraConfigAction();

    const onAppUsernameChange = (value: string) => {
        changeCredentials('username', value);
    };

    const onAppPasswordChange = (value: string) => {
        changeCredentials('password', value);
    };

    return (
        <div className="flex flex-wrap gap-x-16 gap-y-5">
            <Input
                name="App Username"
                placeholder="App Username"
                width="300px"
                value={username}
                onChange={onAppUsernameChange}
            />
            <Input
                name="App Password"
                placeholder="App Password"
                width="300px"
                value={password}
                onChange={onAppPasswordChange}
            />
        </div>
    );
};

export default SectionCredentials;
