import React, { FC } from 'react';
import { LoginButton } from 'components/Applications/login';
import GoogleIcon from 'assets/google.svg';
import FacebookIcon from 'assets/facebook.svg';

interface LoginOptionProps {
    text?: string;
}
const LoginOption: FC<LoginOptionProps> = ({ text = 'Sign in' }) => {
    return (
        <div>
            <div className="text-[7px] text-left">{text} with</div>
            <div className="flex gap-2 mt-1">
                <LoginButton
                    text="Google"
                    variant="outlined"
                    className="w-1/2"
                    icon={
                        <img
                            src={GoogleIcon}
                            alt="Google"
                            className="h-3 mr-1"
                        />
                    }
                />
                <LoginButton
                    text="Facebook"
                    variant="outlined"
                    className="w-1/2"
                    icon={
                        <img
                            src={FacebookIcon}
                            alt="Facebook"
                            className="h-3 mr-1"
                        />
                    }
                />
            </div>
            <div className="mt-1 flex justify-center">
                <LoginButton
                    text="Phone number"
                    variant="outlined"
                    className="w-2/3"
                />
            </div>
        </div>
    );
};

export default LoginOption;
