import React, { FC } from 'react';
import TabItem from './TabItem';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface TabListProps {
    tabs: string[];
}

const TabList: FC<TabListProps> = ({ tabs }) => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className="text-[10px] flex drop-shadow transition-colors duration-1000"
            style={{ backgroundColor: getColor(colorUsage.tab_layout_home) }}
        >
            {tabs.map((tab, i) => (
                <TabItem key={i} text={tab} active={i === 0} />
            ))}
        </div>
    );
};

export default TabList;
