import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface TabItemProps {
    text: string;
    active: boolean;
}
const TabItem: FC<TabItemProps> = ({ text, active }) => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className={`flex-grow text-center w-1/3 pb-1 pt-[6px] `}
            style={{
                color: getColor(colorUsage.tab_layout_home_text),
                borderBottom: `2px ${active ? 'solid' : ''}`,
            }}
        >
            {text}
        </div>
    );
};

export default TabItem;
