import React from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import { Toolbar } from 'components/Applications';
import { SettingPage } from 'components/Applications/setting';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { createContentColorsItems } from 'utils/Colors';
import { ContentColorsItem } from 'components/AppPage/types';

const settingColor = [
    colorUsage.color_primary,
    colorUsage.background_1,
    colorUsage.background_3,
    colorUsage.background_4,
    colorUsage.text_1,
    colorUsage.text_3,
    colorUsage.link_color_visited,
    colorUsage.status_bar_background,
    colorUsage.background_toolbar,
    colorUsage.text_toolbar,
    colorUsage.icon_toolbar,
];

const Setting: React.FC = () => {
    const phoneWidth = '236px';
    const { isDark, setDark, getColor, hasDarkLightVarian } = useConfigUsage();

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            settingColor,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            withoutMiniPlayer={true}
                            toolbar={<Toolbar title="Setting" iconBack />}
                        >
                            <SettingPage />
                        </PhoneFrame>

                        <Switch
                            active={isDark}
                            onChange={setDark}
                            activeText="Dark"
                            offText="Light"
                            removeColorActive={false}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Setting;
