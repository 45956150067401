import React, { createContext, FC, useState } from 'react';
import type { AssetsState } from './types';

export const AssetsContext = createContext<AssetsState>({
    toolbar_logo_on_dark: undefined,
    toolbar_logo_on_light: undefined,

    setToolbarLogoDark: () => {},
    setToolbarLogoLight: () => {},
});

const AssetsProvider: FC = ({ children }) => {
    const [toolbarLogoLight, setToolbarLogoLight] = useState<string>();
    const [toolbarLogoDark, setToolbarLogoDark] = useState<string>();

    const defaultState: AssetsState = {
        toolbar_logo_on_light: toolbarLogoLight,
        toolbar_logo_on_dark: toolbarLogoDark,
        setToolbarLogoDark,
        setToolbarLogoLight,
    };

    return (
        <AssetsContext.Provider value={defaultState}>
            {children}
        </AssetsContext.Provider>
    );
};

export default AssetsProvider;
