import React, { FC } from 'react';
import { ChevronDownIcon, ClockIcon, ShareIcon } from '@heroicons/react/solid';
import image2 from 'assets/images/image-2.jpg';
import { IconLiveVisual, IconPlay } from 'assets/icons';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const ChatHeader: FC = () => {
    const { getColor } = useConfigUsage();

    return (
        <div>
            <div
                className="bg-cover bg-center aspect-[3/2] flex flex-col justify-between relative"
                style={{ backgroundImage: `url(${image2})` }}
            >
                <div
                    className="absolute w-full h-full top-0  transition-all duration-1000"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, ${getColor(
                            colorUsage.gradient_end_color
                        )} , ${getColor(colorUsage.gradient_middle_color)})`,
                    }}
                >
                    <div
                        className="flex gap-2 p-1 mt-4"
                        style={{ color: getColor(colorUsage.text_1) }}
                    >
                        <ChevronDownIcon className="w-8" />
                        <div className="w-full flex justify-between pt-1">
                            <div className="font-bold">
                                <div className="text-[10px]">Radio Name</div>
                                <div className="text-[8px]">Radio Info</div>
                            </div>
                            <ClockIcon
                                className="w-4 mr-2"
                                style={{ color: getColor(colorUsage.icon_1) }}
                            />
                        </div>
                    </div>
                    <div
                        className="flex flex-col items-center pb-2"
                        style={{ color: getColor(colorUsage.text_1) }}
                    >
                        <div
                            className="w-12 h-12 rounded-full"
                            style={{
                                color: 'white',
                                backgroundColor: '#00000070',
                            }}
                        >
                            <IconPlay size="48px" />
                        </div>
                        <div className="text-[10px] pt-2">Now On Air</div>
                        <div className="text-[10px]">Title title title</div>
                    </div>
                </div>
            </div>

            <div
                className="flex justify-end gap-2 px-2 pt-1"
                style={{ color: getColor(colorUsage.text_1) }}
            >
                <div className="flex gap-[2px] items-center">
                    <ShareIcon className="w-3" />
                    <div className="text-[10px] leading-[10px]">Share</div>
                </div>
                <div className="flex gap-[2px] items-center">
                    <IconLiveVisual
                        size="13px"
                        color={getColor(colorUsage.text_1)}
                    />
                    <div className="text-[10px] leading-[10px]">
                        Live Visual
                    </div>
                </div>
            </div>
            <div className="px-2 pb-1 text-xs font-bold">Live Chat</div>
        </div>
    );
};

export default ChatHeader;
