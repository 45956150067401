import React, { FC } from 'react';
import { IconPlay } from 'assets/icons';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PlayButton: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className="absolute top-0 left-[50%] translate-x-[-50%] 
        translate-y-[-50%] bg-white shadow-md flex rounded-md"
        >
            <div
                className="w-20 h-0 border-solid border-t-[28px] 
            border-r-[10px] border-r-transparent 
            border-l-[8px]  rounded-l-md"
                style={{
                    borderTopColor: getColor(colorUsage.color_primary),
                    borderLeftColor: getColor(colorUsage.color_primary),
                }}
            >
                <div className="absolute top-[7px] left-[10px] text-[10px] text-white">
                    Shuffle Play
                </div>
            </div>
            <div
                className="w-5 h-7 flex items-center transition-colors duration-1000"
                style={{ color: getColor(colorUsage.color_primary) }}
            >
                <IconPlay />
            </div>
        </div>
    );
};

export default PlayButton;
