import React, { FC } from 'react';
import { ChevronDownIcon, ClockIcon } from '@heroicons/react/solid';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PlayerHead: FC = () => {
    const { getColor } = useConfigUsage();

    return (
        <div className="flex place-content-between">
            <ChevronDownIcon
                className="w-6 transition-colors duration-1000"
                style={{ color: getColor(colorUsage.icon_1) }}
            />
            <div className="flex gap-4 items-center pr-1">
                <div
                    className="text-[8px] transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.text_1) }}
                >
                    Quality
                </div>
                <ClockIcon
                    className="w-4 transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.icon_1) }}
                />
            </div>
        </div>
    );
};

export default PlayerHead;
