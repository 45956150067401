import React, { FC } from 'react';
import { IconProps } from './type';

const Icon: FC<IconProps> = ({ color = 'white', size = '16px' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="1.875"
                height="12.5"
                rx="0.9375"
                transform="matrix(1 0 0 -1 0.5625 13.4421)"
                fill={color}
            />
            <rect
                width="1.875"
                height="12.5"
                rx="0.9375"
                transform="matrix(1 0 0 -1 5.5625 13.4421)"
                fill={color}
            />
            <rect
                width="1.875"
                height="12.5"
                rx="0.9375"
                transform="matrix(1 0 0 -1 10.5625 13.4421)"
                fill={color}
            />
        </svg>
    );
};
export default Icon;
