import React, { useState, useEffect } from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import {
    ChannelHeaderDefault,
    ChannelHeaderActionBackground,
    ChannelContent,
} from 'components/Applications/channel';
import { useConfigUsage, useSvaraConfig } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { createContentColorsItems } from 'utils/Colors';
import { ContentColorsItem } from 'components/AppPage/types';

const channelColorRaw = [
    colorUsage.color_primary,
    colorUsage.color_accent,
    colorUsage.background_1,
    colorUsage.background_3,
    colorUsage.background_4,
    colorUsage.text_1,
    colorUsage.text_2,
    colorUsage.icon_1,
    colorUsage.gradient_end_color,
    colorUsage.gradient_middle_color,
    colorUsage.background_mini_player,
    colorUsage.text_mini_player,
    colorUsage.channel_action_background,
    colorUsage.channel_action_icon_color,
];

const Channel: React.FC = () => {
    const phoneWidth = '236px';

    const [channelColor, setChannelColor] = useState<string[]>([]);

    const { isDark, setDark, getColor, hasDarkLightVarian } = useConfigUsage();

    const {
        settings: { channelPageStyle },
    } = useSvaraConfig();

    useEffect(() => {
        if (channelPageStyle === 4) {
            setChannelColor(channelColorRaw);
        } else
            setChannelColor(
                channelColorRaw.filter(
                    (c) =>
                        c !== colorUsage.channel_action_background &&
                        c !== colorUsage.channel_action_icon_color
                )
            );
    }, [channelPageStyle]);

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            channelColor,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark, channelColor]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            overlapStatusBar
                            transparentStatusBar
                            useBlackTextSystemUiOnLightTheme={true}
                            allowWhiteSystemUi={false}
                        >
                            <div
                                className="transition-colors duration-1000"
                                style={{
                                    backgroundColor: getColor(
                                        colorUsage.background_1
                                    ),
                                }}
                            >
                                {channelPageStyle === 4 && (
                                    <ChannelHeaderActionBackground />
                                )}
                                {channelPageStyle !== 4 && (
                                    <ChannelHeaderDefault />
                                )}
                                <ChannelContent />
                            </div>
                        </PhoneFrame>

                        <Switch
                            active={isDark}
                            onChange={setDark}
                            activeText="Dark"
                            offText="Light"
                            removeColorActive={false}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Channel;
