import React, { FC, useState } from 'react';
import { IconPinMessage } from 'assets/icons';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const PinMessage: FC = () => {
    const [open, setOpen] = useState(false);
    const { getColor } = useConfigUsage();

    return (
        <div
            className={`absolute top-0 flex justify-end transition-all delay-150 duration-300 overflow-hidden w-full ${
                open ? 'right-0' : 'right-[-184px]'
            }`}
        >
            <div
                className="p-2 w-10 h-10 rounded-full cursor-pointer"
                style={{
                    backgroundColor: getColor(colorUsage.pinnedBackground),
                }}
                onClick={() => setOpen(!open)}
            >
                <div className="origin-bottom -rotate-45">
                    <IconPinMessage color="white" size="14px" />
                </div>
            </div>
            <div
                className="text-[10px] w-5/6 p-2 text-white rounded ml-[-20px]"
                style={{
                    backgroundColor: getColor(colorUsage.pinnedBackground),
                }}
            >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </div>
        </div>
    );
};

export default PinMessage;
