import React, { FC } from 'react';
import { IconProps } from './type';

const Icon: FC<IconProps> = ({ color = 'white', size = '16px' }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.15 13.9671L4.375 8.19211L10.15 2.41711"
                stroke={color}
                strokeWidth="1.875"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default Icon;
