import React, { FC } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react';
import { BaseProps } from 'types';

interface Props extends BaseProps {
    text?: string;
    width?: string;
    checkPosition?: 'left' | 'right';
    checked?: boolean;
    onChange?: (isChecked: boolean) => void;
}
const CheckBox: FC<Props> = ({
    text,
    checkPosition = 'right',
    checked = false,
    className,
    width = '175px',
    onChange = () => {},
}) => {
    const onCheckClick = () => onChange(!checked);

    const Check = () => (
        <div
            className={`w-6 h-6 rounded-md cursor-pointer 
            transition-all duration-500 overflow-hidden ${
                checked ? 'bg-svaraPrimary' : 'bg-white hover:bg-slate-400'
            }`}
            onClick={onCheckClick}
        >
            <TransitionCheckbox checked={checked} />
        </div>
    );
    return (
        <div className={`flex items-center ${className}`}>
            {checkPosition === 'left' && Check()}
            <div
                className={`text-white ${
                    checkPosition === 'left' ? 'pl-3' : 'pr-3'
                }`}
                style={{ width: `calc(${width} - 1.5rem)` }}
            >
                {text}
            </div>
            {checkPosition === 'right' && Check()}
        </div>
    );
};

interface TransitionProps {
    checked?: boolean;
}
export const TransitionCheckbox: FC<TransitionProps> = ({ checked }) => {
    return (
        <Transition
            show={checked}
            enter="transition-transform duration-500"
            enterFrom="-translate-x-[100%] translate-y-[100%]"
            enterTo="translate-x-0 translate-y-0"
            leave="transition-transform duration-500"
            leaveFrom="translate-x-0 translate-y-0"
            leaveTo="translate-x-[100%] -translate-y-[100%]"
        >
            <CheckIcon className="text-white" />
        </Transition>
    );
};

export default CheckBox;
