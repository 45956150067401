import React, { FC } from 'react';
import { ShareIcon, ChatAlt2Icon } from '@heroicons/react/solid';
import { ThumbUpIcon } from '@heroicons/react/outline';
import { colorUsage } from 'provider/context/defaultData';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';

const ItemFooter: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div className="w-full pt-1 flex items-center place-content-between">
            <div
                className="flex gap-2 transition-colors duration-1000"
                style={{ color: getColor(colorUsage.icon_1) }}
            >
                <ShareIcon className="w-4" />
                <ChatAlt2Icon className="w-4" />
                <ThumbUpIcon
                    className="w-4 transition-colors duration-1000"
                    style={{ color: getColor(colorUsage.like_color) }}
                />
            </div>
            <div
                className="text-[8px] flex gap-2 transition-colors duration-1000"
                style={{ color: getColor(colorUsage.text_1) }}
            >
                <div>No Like</div>
                <div>•</div>
                <div>No Comment</div>
            </div>
        </div>
    );
};

export default ItemFooter;
