import React from 'react';
import InputImageAsset from './InputImageAsset';
import useAssets from 'provider/hooks/useAssets';

const ToolbarLogo: React.FC = () => {
    const {
        toolbar_logo_on_dark,
        toolbar_logo_on_light,
        setToolbarLogoDark,
        setToolbarLogoLight,
    } = useAssets();

    const onImageLightCoosed = (imageFile: File) => {
        setToolbarLogoLight(URL.createObjectURL(imageFile));
    };

    const onImageDarkCoosed = (imageFile: File) => {
        setToolbarLogoDark(URL.createObjectURL(imageFile));
    };

    return (
        <div className="flex justify-start items-start gap-2">
            <div className="flex flex-col justify-center items-center">
                <div className="w-52">
                    <InputImageAsset
                        onImageCoosed={onImageLightCoosed}
                        imageFile={toolbar_logo_on_light}
                    />
                </div>

                <div>toolbar_logo_on_light</div>
            </div>
            <div className="flex flex-col justify-center items-center">
                <div className="w-52">
                    <InputImageAsset
                        onImageCoosed={onImageDarkCoosed}
                        imageFile={toolbar_logo_on_dark}
                    />
                </div>

                <div>toolbar_logo_on_dark</div>
            </div>
        </div>
    );
};

export default ToolbarLogo;
