import React, { FC } from 'react';
import {
    SectionTitle,
    ItemTitle,
    ItemSubtitle,
} from 'components/Applications/sections';

import image6 from 'assets/images/image-6.jpg';
import image7 from 'assets/images/image-7.jpg';
import image1 from 'assets/images/image-1.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const Item: FC<{ bg: string }> = ({ bg }) => {
    const { getColor } = useConfigUsage();
    return (
        <div className="w-1/3">
            <div
                className="bg-gray-500 bg-cover bg-center rounded-full aspect-[1/1] w-full"
                style={{ backgroundImage: `url(${bg})` }}
            ></div>
            <div style={{ color: getColor(colorUsage.text_1) }}>
                <ItemTitle />
                <ItemSubtitle />
            </div>
        </div>
    );
};
const SectionCircle: FC = () => {
    return (
        <div className="p-2 w-full overflow-x-hidden">
            <SectionTitle title="Section Circle" />
            <div className="flex gap-2 w-full">
                <Item bg={image6} />
                <Item bg={image7} />
                <Item bg={image1} />
            </div>
        </div>
    );
};

export default SectionCircle;
