import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface LinkTextProps {
    text?: string;
}
const LinkText: FC<LinkTextProps> = ({ text }) => {
    const { getColor } = useConfigUsage();
    return (
        <span
            className="underline"
            style={{ color: getColor(colorUsage.color_primary) }}
        >
            {text}
        </span>
    );
};

export default LinkText;
