import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { SearchIcon } from '@heroicons/react/solid';

const SearchBar: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className="m-2 mb-0 p-[6px] text-xs rounded-md transition-colors duration-1000 flex items-center justify-between"
            style={{
                backgroundColor: getColor(colorUsage.search_page_field),
            }}
        >
            <div
                className="pl-1"
                style={{ color: getColor(colorUsage.search_page_text) }}
            >
                Search...
            </div>
            <SearchIcon
                className="w-4"
                style={{ color: getColor(colorUsage.search_page_icon) }}
            />
        </div>
    );
};

export default SearchBar;
