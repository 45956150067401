import React, { FC } from 'react';
import { Input } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';

const SectionFacebook: FC = () => {
    const {
        facebook: { facebookAppId },
    } = useSvaraConfig();
    const { changeFacebook } = useSvaraConfigAction();

    const onFacebookAppIdChange = (value: string) => {
        changeFacebook('facebookAppId', value);
    };

    return (
        <div className="flex flex-wrap gap-x-16 gap-y-5">
            <Input
                className="md:w-full lg:w-4/5 xl:w-8/12"
                name="Facebook App Id"
                width="100%"
                value={facebookAppId}
                onChange={onFacebookAppIdChange}
            />
        </div>
    );
};

export default SectionFacebook;
