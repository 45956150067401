import React, { FC, SyntheticEvent } from 'react';

interface InputImageAssetProps {
    onImageCoosed?: (imageFile: File) => void;
    imageFile?: string;
}

const InputImageAsset: FC<InputImageAssetProps> = ({
    imageFile,
    onImageCoosed = () => {},
}) => {
    const onFileInput = (event: SyntheticEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files || event.currentTarget.files.length < 1)
            return;

        onImageCoosed(event.currentTarget.files[0]);
    };

    return (
        <div className="w-full flex items-center justify-center">
            <div
                className="relative sm:w-full aspect-[1/1]
             border-dashed border-white border-2 rounded-xl flex items-center justify-center"
            >
                <input
                    type="file"
                    accept="image/png"
                    className="opacity-0 w-full h-full  cursor-pointer absolute"
                    onChange={onFileInput}
                />
                {imageFile && <img src={imageFile} alt="" />}
                {!imageFile && (
                    <div className="text-xs font-bold">
                        Choose File or Drag Here..
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputImageAsset;
