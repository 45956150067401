import React from 'react';
import {
    PageContainer,
    PageContainerContent,
    Toolbar,
} from 'components/Common';

const AppPageLayout: React.FC = ({ children }) => {
    return (
        <PageContainer>
            <Toolbar />
            <PageContainerContent>{children}</PageContainerContent>
        </PageContainer>
    );
};

export default AppPageLayout;
