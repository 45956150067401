import React, { FC, useState, useEffect } from 'react';
import { Title, Input, InputTag } from 'components';
import {
    useSvaraConfig,
    useSvaraConfigAction,
} from 'provider/hooks/SvaraConfigHook';

const SectionApplication: FC = () => {
    const [packagePaths, setPackagePath] = useState<string[]>([]);

    const {
        displayName,
        packageName,
        packagePath,
        legalName,
        legalWelcome,
        baseLegalUrl,
        legalCompany,
    } = useSvaraConfig();

    useEffect(() => {
        if (packagePath.length > 0) setPackagePath(packagePath.split('/'));
        else setPackagePath([]);
    }, [packagePath]);

    const { changeInfo } = useSvaraConfigAction();

    const onPackagePathChange = (values: string[]) => {
        changeInfo('packagePath', values.join('/'));
    };

    const onInfoChange = (key: string, value: string) => changeInfo(key, value);

    return (
        <div>
            <Title text="Core" />
            <div className="mt-4 flex flex-wrap gap-x-16 gap-y-5">
                <Input
                    name="Display Name"
                    placeholder="Svara"
                    width="300px"
                    value={displayName}
                    onChange={(val) => onInfoChange('displayName', val)}
                />
                <Input
                    name="Package Name"
                    placeholder="svara"
                    width="300px"
                    value={packageName}
                    onChange={(val) => onInfoChange('packageName', val)}
                />
            </div>
            <InputTag
                className="mt-5 md:w-[664px] sm:w-full"
                name="Package Path"
                onValueChange={onPackagePathChange}
                values={packagePaths}
                placeholder="Type a text and press enter / tab to add."
                showInfo
                width="100%"
                spacing="/"
            />
            <Title text="Legal" className="mt-10" />
            <div className="mt-4 flex flex-wrap gap-x-16 gap-y-5">
                <Input
                    name="Legal Name"
                    placeholder="Svara"
                    width="300px"
                    value={legalName}
                    onChange={(val) => onInfoChange('legalName', val)}
                />
                <Input
                    name="Legal Welcome"
                    placeholder="Welcome to SVARA Platform"
                    width="300px"
                    value={legalWelcome}
                    onChange={(val) => onInfoChange('legalWelcome', val)}
                />
                <Input
                    name="Legal Base url"
                    placeholder="https://legal.svara.id/svara"
                    width="300px"
                    value={baseLegalUrl}
                    onChange={(val) => onInfoChange('baseLegalUrl', val)}
                />
                <Input
                    name="Legal Company"
                    placeholder="PT. Svara Inovasi Indonesia"
                    width="300px"
                    value={legalCompany}
                    onChange={(val) => onInfoChange('legalCompany', val)}
                />
            </div>
        </div>
    );
};

export default SectionApplication;
