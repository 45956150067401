import React, { FC } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import image1 from 'assets/images/image-1.jpg';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const ItemHead: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div className="flex w-full gap-2">
            <div
                className="bg-gray-500 bg-cover bg-center rounded-full aspect-[1/1] w-6 h-6"
                style={{ backgroundImage: `url(${image1})` }}
            />
            <div className="flex-grow">
                <div className="flex gap-2 text-[11px]">
                    <div
                        className="font-bold transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_1) }}
                    >
                        First Last Name
                    </div>
                    <div
                        className="text-gray-500 transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_2) }}
                    >
                        @username
                    </div>
                </div>
                <div className="text-[8px] text-gray-400 ">
                    <span
                        className="transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_2) }}
                    >
                        share a post •{' '}
                    </span>
                    <span
                        className="transition-colors duration-1000"
                        style={{ color: getColor(colorUsage.text_3) }}
                    >
                        Feb 14 2022 • 2 jam yang lalu
                    </span>
                </div>
            </div>
            <div>
                <DotsVerticalIcon
                    className="w-4 transition-colors duration-1000"
                    color={getColor(colorUsage.icon_1)}
                />
            </div>
        </div>
    );
};

export default ItemHead;
