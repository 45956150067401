import React, { FC } from 'react';
import { BaseProps } from 'types';

interface ContainerProps extends BaseProps {}
const PageContainer: FC<ContainerProps> = ({ children, className }) => {
    return (
        <div
            className={`min-h-screen min-w-full bg-svaraBackground font-nunito text-white ${className}`}
        >
            {children}
        </div>
    );
};

const Content: FC<BaseProps> = ({ children, className }) => (
    <div className={`w-full z-0 ${className}`}>{children}</div>
);

export default PageContainer;
export { Content as PageContainerContent, PageContainer };
