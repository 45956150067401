import React from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { Toolbar, Tabbar } from 'components/Applications';
import { colorUsage } from 'provider/context/defaultData';
import { ContentColorsItem } from 'components/AppPage/types';
import {
    SectionWhatsNew,
    SectionMenu,
    SectionSquare,
    SectionCircle,
    SectionStayTune,
} from 'components/Applications/sections';
import { createContentColorsItems } from 'utils/Colors';
import { TabList } from 'components/Applications/tabs';

const homeColor = [
    colorUsage.color_primary,
    colorUsage.color_accent,
    colorUsage.background_1,
    colorUsage.background_3,
    colorUsage.background_4,
    colorUsage.text_1,
    colorUsage.text_4,
    colorUsage.background_toolbar,
    colorUsage.icon_toolbar,
    colorUsage.text_toolbar,
    colorUsage.tab_layout_home,
    colorUsage.tab_layout_home_text,
    colorUsage.icon_tab,
    colorUsage.status_bar_background,
    colorUsage.status_bar_background_invert,
    colorUsage.background_mini_player,
    colorUsage.text_mini_player,
    colorUsage.gradient_card_bg_start_color,
    colorUsage.gradient_card_bg_middle_color,
    colorUsage.gradient_card_bg_end_color,
];

const Home: React.FC = () => {
    const phoneWidth = '236px';
    const { isDark, setDark, getColor, hasDarkLightVarian } = useConfigUsage();

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            homeColor,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            toolbar={<Toolbar title="HOME" withProfile />}
                            tabbar={<Tabbar activeAt="home" />}
                        >
                            <TabList tabs={['Svara', 'Channel', 'Podcast']} />
                            <SectionWhatsNew />
                            <SectionMenu />
                            <SectionStayTune />
                            <SectionSquare />
                            <SectionCircle />
                        </PhoneFrame>

                        <Switch
                            active={isDark}
                            onChange={setDark}
                            activeText="Dark"
                            offText="Light"
                            removeColorActive={false}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Home;
