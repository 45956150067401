import React, { FC } from 'react';
import { AppPagelayout } from 'layout';
import { SectionTitle, ToolbarLogo } from 'components/Applications/assets';

const Assets: FC = () => {
    return (
        <AppPagelayout>
            <div className="flex flex-col w-full">
                <div className="flex flex-col">
                    <SectionTitle>Toolbar Logo</SectionTitle>
                    <ToolbarLogo />
                </div>
            </div>
        </AppPagelayout>
    );
};

export default Assets;
