import React, { FC } from 'react';
import { BadgeItem } from 'components/Applications/library';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

interface BadgeBarProps {
    title?: string;
    contents?: string[];
    selectedIndex?: number;
}

const BadgeBar: FC<BadgeBarProps> = ({
    title,
    contents,
    selectedIndex = -1,
}) => {
    const { getColor } = useConfigUsage();

    return (
        <div>
            <div
                className="font-bold text-xs pb-[2px]"
                style={{ color: getColor(colorUsage.text_1) }}
            >
                {title}
            </div>
            <div className="flex gap-1 flex-wrap">
                {contents?.map((text, index) => (
                    <BadgeItem
                        key={text + index}
                        text={text}
                        selected={selectedIndex === index}
                    />
                ))}
            </div>
        </div>
    );
};

export default BadgeBar;
