import React, { FC } from 'react';
import { ListItem } from 'components/Applications/playlist';
import { UserIcon } from '@heroicons/react/solid';
import image1 from 'assets/images/image-1.jpg';
import image2 from 'assets/images/image-2.jpg';
import image3 from 'assets/images/image-3.jpg';

const datas = [
    { img: image1, title: 'Hits Indonesia', user: 'svara' },
    { img: image2, title: 'Hits Mancanegara', user: 'svara' },
    { img: image3, title: 'Lagu Sedih', user: 'username' },
];

const LibraryContent: FC = () => {
    return (
        <div className="pt-1">
            {datas.map((data, i) => (
                <ListItem
                    key={i}
                    img={data.img}
                    title={data.title}
                    line2={{
                        icon: <UserIcon className="h-[10px]" />,
                        text: data.user,
                    }}
                    smList
                />
            ))}
        </div>
    );
};

export default LibraryContent;
