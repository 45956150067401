import React, { FC } from 'react';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';

const ProfileContentTab: FC = () => {
    const { getColor } = useConfigUsage();
    return (
        <div
            className="flex text-[10px] overflow-hidden transition-colors duration-1000"
            style={{
                backgroundColor: getColor(colorUsage.background_1),
                color: getColor(colorUsage.text_2),
            }}
        >
            <div
                className="relative transition-colors duration-1000"
                style={{ color: getColor(colorUsage.text_1) }}
            >
                <div className="p-2">Timeline</div>
                <div
                    className="absolute w-full h-[2px] bottom-0"
                    style={{
                        backgroundColor: getColor(colorUsage.color_primary),
                    }}
                ></div>
            </div>
            <div className="p-2">Podcast</div>
            <div className="p-2">Series</div>
            <div className="p-2">Playlist</div>
            <div className="p-2">Video</div>
        </div>
    );
};

export default ProfileContentTab;
