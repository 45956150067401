const toStartCase = (value: string): string => {
    return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
        return str.toUpperCase();
    });
};

const underscoreToSpace = (text?: string): string =>
    (text || '').replace(/_/g, ' ');

export { toStartCase, underscoreToSpace };
