import React, { FC } from 'react';
import { IconProps } from './type';

const IconPlaylist: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg
            viewBox="0 0 28 24"
            fill="none"
            width={size}
            height={size}
            className="transition-colors duration-1000"
        >
            <path
                d="M0.770004 1.67432C0.770004 1.67432 0.626004 1.73432 0.998004 2.18432L0.770004 1.67432Z"
                fill="currentColor"
            />
            <path
                d="M23.51 2.38199H1.49C1.094 2.38199 0.770004 2.05799 0.770004 1.66199V1.31399C0.770004 0.917994 1.094 0.593994 1.49 0.593994H23.51C23.906 0.593994 24.23 0.917994 24.23 1.31399V1.66199C24.23 2.05799 23.906 2.38199 23.51 2.38199Z"
                fill="currentColor"
            />
            <path
                d="M23.51 6.1503H1.48999C1.09399 6.1503 0.769989 5.8263 0.769989 5.4303V5.0823C0.769989 4.6863 1.09399 4.3623 1.48999 4.3623H23.51C23.906 4.3623 24.23 4.6863 24.23 5.0823V5.4303C24.23 5.8263 23.906 6.1503 23.51 6.1503Z"
                fill="currentColor"
            />
            <path
                d="M14.09 15.5522H1.48999C1.09399 15.5522 0.769989 15.8762 0.769989 16.2722V16.6202C0.769989 17.0162 1.09399 17.3402 1.48999 17.3402H14.09V15.5522Z"
                fill="currentColor"
            />
            <path
                d="M14.06 11.8203H1.48999C1.09399 11.8203 0.769989 12.1443 0.769989 12.5403V12.8883C0.769989 13.2843 1.09399 13.6083 1.48999 13.6083H14.06V11.8203Z"
                fill="currentColor"
            />
            <path
                d="M14.12 8.09424H1.48999C1.09399 8.09424 0.769989 8.41824 0.769989 8.81424V9.16224C0.769989 9.55824 1.09399 9.88224 1.48999 9.88224H14.12V8.09424Z"
                fill="currentColor"
            />
            <path
                d="M22.016 9.87614H23.51C23.906 9.87614 24.23 9.55214 24.23 9.15614V8.80813C24.23 8.41213 23.906 8.08813 23.51 8.08813H19.496L22.016 9.87614Z"
                fill="currentColor"
            />
            <path
                d="M15.2 8.98835V21.8224C15.2 21.8224 15.644 24.3244 18.188 23.0344L26.66 16.9384C26.66 16.9384 27.71 15.7264 27.026 14.5564L18.47 8.50236C18.47 8.50236 15.968 6.44435 15.2 8.98835Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconPlaylist;
