import React, { FC } from 'react';
import { IconProps } from './type';

const IconMic: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 222 222" fill="none" width={size} height={size}>
            <path
                d="M97 30.4C96.5893 29.9861 96.0932 29.667 95.5462 29.465C94.9993 29.263 94.4148 29.183 93.8336 29.2306C93.2525 29.2782 92.6889 29.4523 92.1821 29.7407C91.6753 30.0291 91.2378 30.4247 90.9 30.9C82.5006 42.9597 77.9985 57.3036 78 72C77.9811 74.5723 78.1146 77.1435 78.4 79.7L3.59998 181.7C1.33467 184.785 0.250416 188.58 0.543977 192.397C0.837538 196.213 2.48946 199.798 5.19999 202.5L19.5 216.8C22.2025 219.511 25.7871 221.162 29.6034 221.456C33.4197 221.75 37.2148 220.665 40.3 218.4L142.3 143.6C144.856 143.885 147.428 144.019 150 144C164.696 144.001 179.04 139.499 191.1 131.1C191.575 130.762 191.971 130.325 192.259 129.818C192.548 129.311 192.722 128.747 192.769 128.166C192.817 127.585 192.737 127.001 192.535 126.454C192.333 125.907 192.014 125.411 191.6 125L97 30.4ZM95.7 137.7L75.7 157.7C74.1709 159.179 72.127 160.005 70 160.005C67.8729 160.005 65.8291 159.179 64.3 157.7C62.7977 156.183 61.955 154.135 61.955 152C61.955 149.865 62.7977 147.817 64.3 146.3L84.3 126.3C85.8544 125.024 87.8277 124.372 89.8361 124.471C91.8445 124.57 93.7444 125.412 95.1663 126.834C96.5882 128.256 97.4303 130.155 97.529 132.164C97.6276 134.172 96.9757 136.146 95.7 137.7V137.7ZM209.1 113.1C208.762 113.575 208.325 113.971 207.818 114.259C207.311 114.548 206.747 114.722 206.166 114.769C205.585 114.817 205.001 114.737 204.454 114.535C203.907 114.333 203.411 114.014 203 113.6L108.4 19C107.986 18.5893 107.667 18.0932 107.465 17.5462C107.263 16.9993 107.183 16.4148 107.231 15.8337C107.278 15.2525 107.452 14.6889 107.741 14.1821C108.029 13.6754 108.425 13.2378 108.9 12.9C122.764 3.28031 139.566 -1.16423 156.374 0.342385C173.181 1.849 188.925 9.21105 200.857 21.1431C212.789 33.0752 220.151 48.8193 221.658 65.6264C223.164 82.4336 218.72 99.236 209.1 113.1V113.1Z"
                fill={color}
            />
        </svg>
    );
};

export default IconMic;
