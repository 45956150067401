import React from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import { ChatHeader, ChatList, TextBox } from 'components/Applications/chat';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { colorUsage } from 'provider/context/defaultData';
import { ContentColorsItem } from 'components/AppPage/types';
import { createContentColorsItems } from 'utils/Colors';

const chatColors = [
    colorUsage.icon_1,
    colorUsage.text_1,
    colorUsage.gradient_end_color,
    colorUsage.gradient_middle_color,
    colorUsage.text_2,
    colorUsage.pinnedBackground,
    colorUsage.transparent_70,
];
const Chat: React.FC = () => {
    const phoneWidth = '236px';
    const { isDark, setDark, getColor, hasDarkLightVarian } = useConfigUsage();

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            chatColors,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            withoutMiniPlayer
                            overlapStatusBar
                            transparentStatusBar
                        >
                            <div className="h-full overflow-hidden">
                                <ChatHeader />
                                <ChatList />
                                <TextBox />
                            </div>
                        </PhoneFrame>

                        <Switch
                            active={isDark}
                            onChange={setDark}
                            activeText="Dark"
                            offText="Light"
                            removeColorActive={false}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Chat;
