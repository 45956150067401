import React, { FC, SyntheticEvent } from 'react';
import { BaseProps } from 'types';

interface Props extends BaseProps {
    name?: string;
    type?: 'text' | 'number' | 'password';
    placeholder?: string;
    value?: string;
    width?: string;
    onChange?: (value: string) => void;
}
const Input: FC<Props> = ({
    type = 'text',
    name,
    placeholder,
    value,
    width,
    onChange,
    className,
}) => {
    const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
        if (onChange) onChange(event.currentTarget.value);
    };
    return (
        <div className={className}>
            {!!name && <div className="text-base">{name}</div>}

            <input
                className={`text-black rounded-xl h-[34px] px-3 shadow-md caret-svaraPrimary ${
                    !!name && 'mt-2'
                }`}
                type={type}
                style={{ width }}
                onChange={handleChange}
                placeholder={placeholder}
                value={value}
            />
        </div>
    );
};

export default Input;
