import React, { useEffect, useState } from 'react';
import AppPageLayout from 'layout/AppPagelayout';
import { ContentColors, PhoneFrame, Switch } from 'components';
import { colorUsage } from 'provider/context/defaultData';
import { useConfigUsage } from 'provider/hooks/SvaraConfigHook';
import { ContentColorsItem } from 'components/AppPage/types';
import { createContentColorsItems } from 'utils/Colors';

import {
    LoginHeader,
    LoginForm,
    LoginOption,
    ToRegister,
    RegisterForm,
} from 'components/Applications/login';

const loginColor = [
    colorUsage.color_primary,
    colorUsage.text_1,
    colorUsage.color_accent,
];

const Login: React.FC = () => {
    const phoneWidth = '236px';

    const [isLoginPage, setIsLoginPage] = useState(true);

    const { setDark, getColor, isDark, hasDarkLightVarian } = useConfigUsage();

    useEffect(() => {
        setDark(false);
    }, [setDark]);

    const getColorsToShow = React.useCallback((): ContentColorsItem[] => {
        return createContentColorsItems(
            loginColor,
            isDark,
            getColor,
            hasDarkLightVarian
        );
    }, [getColor, hasDarkLightVarian, isDark]);

    return (
        <AppPageLayout>
            <div className="w-full flex overflow-x-hidden relative">
                <div
                    className={`overflow-hidden p-4`}
                    style={{
                        width: `calc(100vw - ${phoneWidth})`,
                    }}
                >
                    <ContentColors contents={getColorsToShow()} />
                </div>
                <div
                    className={`flex grow-0 mx-4`}
                    style={{
                        width: phoneWidth,
                    }}
                >
                    <div className="flex flex-col fixed top-toolbar right-4 mt-4 gap-y-2">
                        <PhoneFrame
                            width={phoneWidth}
                            withoutMiniPlayer
                            transparentStatusBar={true}
                            overlapStatusBar
                            useBlackTextSystemUiOnLightTheme={true}
                            allowWhiteSystemUi={false}
                        >
                            <div className="flex flex-col p-2 h-full bg-white pt-8">
                                {isLoginPage ? (
                                    <React.Fragment>
                                        <LoginHeader />
                                        <div className="flex-grow flex flex-col justify-between text-center w-4/5 self-center">
                                            <LoginForm />
                                            <LoginOption />
                                            <ToRegister
                                                text="Don't have an account?"
                                                link="Register"
                                            />
                                            <div className="h-1"></div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {' '}
                                        <LoginHeader />
                                        <div className="flex-grow flex flex-col justify-between text-center w-4/5 self-center pb-8">
                                            <RegisterForm />
                                            <LoginOption text="Sign up" />
                                            <ToRegister
                                                text="Have an account?"
                                                link="Sign In"
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </PhoneFrame>

                        <Switch
                            active={isLoginPage}
                            onChange={setIsLoginPage}
                            activeText="Login"
                            offText="Register"
                            removeColorActive={true}
                            size="lg"
                        />
                    </div>
                </div>
            </div>
        </AppPageLayout>
    );
};

export default Login;
