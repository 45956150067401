import React, { FC } from 'react';
import { IconProps } from './type';

const IconPrev: FC<IconProps> = ({ color = 'black', size = '16px' }) => {
    return (
        <svg viewBox="0 0 23 24" width={size} height={size}>
            <path
                d="M4.47282 0.186951C5.58448 0.186951 6.48565 1.08795 6.48565 2.19938V21.1737C6.48565 22.2852 5.58448 23.1862 4.47282 23.1862C3.36117 23.1862 2.45999 22.2852 2.45999 21.1737V2.19938C2.45999 1.08795 3.36117 0.186951 4.47282 0.186951Z"
                fill="currentColor"
            />
            <path
                d="M20.1805 20.2868L11.7576 11.6825L20.1711 3.08807C20.6513 2.5978 20.6513 1.79937 20.1712 1.30891L19.4333 0.555356C19.2014 0.318259 18.8922 0.187742 18.5623 0.187742C18.2323 0.187742 17.9231 0.318259 17.6914 0.555356L7.67197 10.7896C7.43913 11.0273 7.31191 11.3442 7.31283 11.683C7.31191 12.0204 7.43931 12.3377 7.67197 12.5752L17.7007 22.8193C17.9325 23.0564 18.2416 23.187 18.5715 23.187C18.9013 23.187 19.2109 23.0564 19.4424 22.8193L20.1805 22.0658C20.4124 21.8291 20.54 21.5132 20.54 21.1761C20.54 20.8392 20.4124 20.5232 20.1805 20.2868Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default IconPrev;
