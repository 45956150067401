import React, { FC } from 'react';
import {
    ItemHead,
    ItemContent,
    ItemFooter,
} from 'components/Applications/social';

const SocialItem: FC = () => {
    return (
        <div className="p-2">
            <ItemHead />
            <ItemContent />
            <ItemFooter />
        </div>
    );
};

export default SocialItem;
